
export class HalResource {

    constructor(
      public resource?: any,
      public identity?: string,
      public properties: Map<string, any> = new Map(),
      public embedded: Map<string, Array<HalResource>> = new Map(),
      public links?: Map<string, any>,
      public isError: boolean = false
    ) {}
  
    firstEmbeddedByKey(key: string) :any {
      if (this.embedded && this.embedded[key]) {
        return this.embedded[key][0];
      }
      return {};
    }
  
    getAllEmbeddedResourceByName(key: string) :HalResource[] {
      if (this.embedded && this.embedded[key]) {
        return this.embedded[key];
      } 
      return [];
    }
  
    resolvePropertyOnResource(key: string, propName: string): string {
      let external = this.firstEmbeddedByKey(key);
      if (external) {
        return external.properties[propName];
      }
      return '';
    }
}

export class HalLink {
    constructor(
        public name: string,
        public href: string,
    ) {}
}

export class ErrorResource {

  constructor(
    public response?: any,
    public status?: number,
    public message?: string
  ) {}
}