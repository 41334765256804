import React, {FunctionComponent, useState} from 'react';
import {EditTwoTone} from "@material-ui/icons";

import {ResourceName} from "../../../../model/common/ResourceName";
import {Status} from '../../../../components/hoc/withHalContext';
import {useMutableHalResource} from '../../../../components/hoc/withMutateHalResource';
import {CrudMode} from '../../../../model/common/Common';
import {HalResource} from '../../../../model/common/HalResource';
import {Maybe} from '../../../../model/common/Maybe';
import {FormSummaryData, mapRowToType} from '../../../../model/common/ResourceDataTypes';
import {useHistory} from 'react-router';
import {ResourceBuilder} from '../../../../model/common/ResourceBuilder';


export enum RenderMode {
    NORMAL,
    SMALL
}

export type ActionProps = {
    handleEditForm: (event: any) => void,
    handlePublish: () => void,
    handleNewDraft: () => void,
    handleArchive: () => void,
    handlePreview: () => void,
    handleListMultiFormCaptures: () => void
}

export type ActionKeys = {
    publishKey: ResourceName,
    newDraftKey: ResourceName,
    archiveKey: ResourceName
}

export type TilePropsNoActions = {
    data: FormSummaryData,
    status: Status,
    renderMode?: RenderMode
}

export type TileProps = { 
    actions: ActionProps,
} & TilePropsNoActions

type Props = {
   row: HalResource,
   actionKeys: ActionKeys,
   editPathName: string,
   renderMode?: RenderMode,
   tileComponent: any
}

const FormItemWithAction : FunctionComponent<Props> = (props: Props) => {

    const history = useHistory();
    const [status, setStatus] = useState<Status>(Status.INIT)
    const [row, setRow] = useState<any>(props.row)

    const onPostAction = (resource : Maybe<HalResource>, status: Status) => {
        console.log('got updated resource ', resource, status)
        setStatus(status)
        if (status === Status.LOADED) {
            resource.map(res => setRow(res.resource))
        }
    };

    const mutateHook = useMutableHalResource({
        createdResourceName: ResourceName.CREATE_FORM_DEFINITION,
        updatedResourceName: ResourceName.FORM_DEFINITION_ACTION_PUBLISH,
        postSubmit: onPostAction
    })

    const onDoAction = (resourceUrl: string) => {
        console.log('updating published versionStatus', resourceUrl)
        setStatus(Status.LOADING)
        mutateHook.handleMutation(null, CrudMode.UPDATE, resourceUrl)
    }

    const handleEditForm = (event: any) => {
        console.log('handle edit we have link ', row.links);
        const linksAsMap = ResourceBuilder.transform(row.links);

        history.push({
            pathname: props.editPathName,
            state:{
                resourceUrl: linksAsMap.get('self')
            }
        });
    };

    const handleAction = (resourceName: ResourceName, link: any) => {
        const linksAsMap = ResourceBuilder.transform(link)
        const formActionLink = linksAsMap.get(resourceName);
        console.log(`handle action ${resourceName} we have link `, formActionLink);
        onDoAction(formActionLink)
    }

    const handlePublish= () => {
       handleAction(props.actionKeys.publishKey, row.links)
    }

    const handleNewDraft = () => {
        handleAction(props.actionKeys.newDraftKey, row.links)
    }

    const handleArchive = () => {
        handleAction(props.actionKeys.archiveKey, row.links)
    }

    const handlePreview = () => {
        const linksAsMap = ResourceBuilder.transform(row.links);
        const formActionLink = linksAsMap.get(ResourceName.MULTI_FORM_DEFINITION_SECTIONS);
        history.push({
            pathname: "/admin/formBuilder/multi/preview",
            state:{
                resourceUrl: formActionLink
            }
        });
    }

    const handleListMultiFormCaptures = () => {
        const linksAsMap = ResourceBuilder.transform(row.links)
        const listMultiFormCapturesLink = linksAsMap.get(ResourceName.MULTI_FORM_DEFINITION_CAPTURE_DATA)
        const multiFormLink = linksAsMap.get('self')
        history.push({
            pathname: "/admin/formBuilder/multi/dashboard",
            state: {
                formCaptureUrl: listMultiFormCapturesLink,
                multiFormUrl: multiFormLink
            }
        })
    }

    const actions = () : ActionProps => {
        return {
            handleEditForm: handleEditForm,
            handlePublish: handlePublish,
            handleNewDraft: handleNewDraft,
            handleArchive: handleArchive,
            handlePreview: handlePreview,
            handleListMultiFormCaptures: handleListMultiFormCaptures
        }
    }

    const renderTile = (tileProps: TileProps) => {
        return React.createElement(props.tileComponent, tileProps)
    }

    const withTileProps = (): TileProps => {
        return {
            status: status,
            actions: actions(),
            data: mapRowToType(row),
            renderMode: props.renderMode,
        }
    }

    return (

        renderTile(
            withTileProps()
            )
    )
};

export default FormItemWithAction