import {Maybe} from "../../../../model/common/Maybe";
import {HalResource} from "../../../../model/common/HalResource";
import {jsonToMap} from "../../../../model/common/ResourceBuilder";

export type MultiFormData = {
    formName: string,
    formType: string,
    formDescription: string,
}

export type FormSummary = {
    formType: string,
    totalFields: number,
    totalSteps: number
}

export const bindFormCaptureData = (resource: Maybe<HalResource>) => {

    const getFormCaptureData = () : Map<string, any> => {
        const maybeMap: Maybe<any> = resource.map(res => res.properties['data'])
        return maybeMap.map(sections => jsonToMap(sections)).getOrElse(new Map())
    }

    return {getFormCaptureData}
}

export const bindMetaData = (resource: Maybe<HalResource>) => {

    const getFormName = (): string => {
        return resource.map(res => res.resource['source']['artifact']).getOrElse("")
    }

    const getFormSummary = (): FormSummary => {
        return resource.map(res => res.properties['formSummary']).getOrElse({})
    }

    const getFormData = (): MultiFormData => {

        const props = resource.map(res => res.properties['formData'])
        
        const defaultData = {
            formName: getFormName(),
            formType: 'wizard',
            formDescription: ''
        }

        return props.getOrElse(defaultData)
    }

    return {getFormName, getFormData, getFormSummary}
}