import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './styles.scss'
import './i18n';
import { Provider } from 'mobx-react';
import RootStore from './model/store/RootStore';
import { StoreProvider } from './model/store/StoreProvider';
import {registCusomComponents}  from "./components/common/form-io/Register";

const rootStore: RootStore = new RootStore();

//register custom form io components
registCusomComponents()

ReactDOM.render(
    <StoreProvider value={rootStore}>
        <Provider rootStore={rootStore}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </StoreProvider>
      
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
