import { observable, action } from "mobx";

export default class LocalStore {

    @observable currentLanguage: string = 'en';

    @action
    public setCurrentLanguage = (lang: string) => {
        this.currentLanguage=lang
    }
}