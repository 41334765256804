import React, {FunctionComponent} from 'react';

import {Grid, GridListTile, makeStyles, Paper, Typography, LinearProgress, Badge, Tooltip} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {createStyles, Theme} from '@material-ui/core/styles';
import Moment from 'react-moment';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import FormEditOptions from '../../../../components/form-builder/FormEditOptions'
import { Status } from '../../../../components/hoc/withHalContext';
import { FormSummaryData } from '../../../../model/common/ResourceDataTypes';
import { RenderMode, TileProps } from './FormItemWithAction';

const FormSummaryTile : FunctionComponent<TileProps> = (props: TileProps) => {

    const classes = useStyles();

    const getFormType = () => {
        const type = props.data.formSummary.formType
        return type ? type.substring(0,1).toUpperCase() : "F"
    }

    return (
      <GridListTile cols={2} className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
            <Grid item xs={2} sm={1}>
                <Typography data-testid="formName" variant="body2" color="textPrimary">
                  {props.data.formName}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                {props.status === Status.LOADING ? (
                    <LinearProgress/>
                ) :(
                    <FormEditOptions 
                        link={props.data.link}
                        onEdit={props.actions.handleEditForm} 
                        onDelete={props.actions.handleArchive}
                        onPublish={props.actions.handlePublish} 
                        onReDraft={props.actions.handleNewDraft} 
                    />
                )}
               
            </Grid>
            <Grid item xs={1}>
                <Tooltip title={'Form Type - ' + props.data.formSummary.formType}>
                    <Avatar data-testid="formTypeAvatar" className={classes.orange}>{getFormType()}</Avatar>
                </Tooltip>
               
            </Grid>
            <Grid item xs={1}>
                    
                <Badge color="secondary" badgeContent={props.data.formSummary.totalFields}>
                    <div className={classes.shape}>
                        FIELDS
                     </div>   
                </Badge>    
            </Grid>
            <Grid item xs={2}>
                  <Typography data-testid="versionStatus" variant="body2" color="textPrimary">
                      Status: {props.data.versionStatus}
                  </Typography>
                  <Typography data-testid="version" variant="body2" color="textPrimary">
                    Version: {props.data.version}
                </Typography>
              </Grid>
            {props.renderMode && props.renderMode === RenderMode.SMALL ?  (
                <Grid item>
                </Grid>
            ) : (
                <Grid item>
                
                    <Grid item>
                        <Typography data-testid="createdTime" variant="caption" color="textPrimary">
                            Created: <Moment
                                format="YYYY-MM-DD HH:mm:ss"
                            >{props.data.created}
                            </Moment>
                        </Typography>
                    </Grid>
                
                    <Grid item>
                        <Typography data-testid="updatedTime" variant="caption" color="textPrimary">
                            Updated: <Moment
                                format="YYYY-MM-DD HH:mm:ss"
                            >{props.data.updated}
                            </Moment>
                        </Typography>
                    </Grid>
                </Grid>
            ) }
            
          </Grid>
        </Paper>
      </GridListTile>
    )
};

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: '100%',
            justifyContent: 'space-around',
            backgroundColor: theme.palette.background.paper
        },
        paper: {
            fontSize: '0.7em',
            width: '100%',
            padding: 5,
            margin: 5,
        },
        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        purple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        shape: {
            backgroundColor: theme.palette.primary.main,
            width: 40,
            height: 20,
            color: 'white',
            alignItems: 'center'
        },
    }),
);

const areEqual = (prevProps:FormSummaryData, nextProps:FormSummaryData) => {
    return prevProps.updated !== nextProps.updated;
}

//export default React.memo(FormSummaryTile, areEqual);
export default FormSummaryTile