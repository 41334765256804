import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Maybe } from '../../../../model/common/Maybe';
import { HalResource } from '../../../../model/common/HalResource';
import FormBuilder from './FormBuilder';
import RootStore from '../../../../model/store/RootStore';
import { withHalPassStateHOC, WithHalResourceStatusProps } from '../../../../components/hoc/withHalResourcePassState';
import { Status } from '../../../../components/hoc/withHalContext';
import { CrudMode } from '../../../../model/common/Common';

type Props = {
    rootStore: RootStore
} & RouteComponentProps & WithHalResourceStatusProps

class EditFormDefinition extends React.Component<Props> {
    
    getExistingForm = () : Maybe<HalResource> => {
        return this.props.resource
    }

    render() {
        const data = this.getExistingForm()
        return (
            <div>
                 {this.props.status === Status.LOADED ? 
                  (<FormBuilder
                    resource={data}
                    mode={CrudMode.UPDATE}
                />) :
                 (<div>Loading....</div>) 
            }
            </div>
        )
    }
}

export default withHalPassStateHOC(withRouter(EditFormDefinition))