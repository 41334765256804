import * as React from 'react';
import { useObserver, } from 'mobx-react-lite';
import { useState} from 'react';
import { ErrorResource } from '../../model/common/HalResource';
import { Maybe } from '../../model/common/Maybe';
import { useHalStore } from '../../model/store/StoreProvider';

type Props = {}

/*
* Custom Hook which checks if we have errors
*/
export const useErrorResource = (props: Props) => {

    const halStore = useHalStore()
    const [errorResource, setErrorResource] = useState<Maybe<ErrorResource>>(Maybe.none())
    const [haveErrors, setHaveErrors] = useState<boolean>(false)

    const checkCallBack = useObserver(() => {

        const errorList = halStore.errors
        //console.log('error size ', errorList.length)
        if (errorList.length> 0 && haveErrors ===false) {
            setHaveErrors(true)
            //drain the errors
            errorList.map((error, index) => {
                setErrorResource(Maybe.some(error))
            })
            return Maybe.some(new Boolean(true))
        } 
        return Maybe.some(new Boolean(false))
    })

    const checkHaveErrors = () => {
        if (haveErrors === false) {
            checkCallBack.map(err => err)
        }
        return {errorResource, haveErrors, checkHaveErrors}
    }

    const clearErrors = () => {
        halStore.clearAllErrors()
        setHaveErrors(false)
    }

    return {errorResource, haveErrors, checkHaveErrors, clearErrors}
}