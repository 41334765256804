import { observable, action } from "mobx";
import { Maybe } from "../common/Maybe";

export default class AuthStore {

    @observable isAuthenticated: boolean = false;

    @observable userName: Maybe<string> = Maybe.none();

    @action setCurrentUser = (userName: Maybe<string>, authenticated: boolean) => { 
        this.userName = userName;
        this.isAuthenticated = authenticated;
    } 

    @action login = (userName: string, password: string) => {

        //check for login details
        if (userName === "admin" && password === "n1njasr0ck") {
            console.log('all good letting you in ', userName)
            this.setCurrentUser(Maybe.some(userName), true)
        } else {
            this.setCurrentUser(Maybe.none(), false)
        }
    }

    @action logout = () => {
        this.setCurrentUser(Maybe.none(), false)
    }
}