import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, GridList } from "@material-ui/core";
import { useHistory } from "react-router";
import UserProfileList from './UserProfileList';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        padding: '50px 24px;',
        width: '100%',
    },
    subHeader: {

    }
  }),
);

const UserProfileOverview: FunctionComponent<Props> = ({}) => {
    const classes = useStyles()
    const history = useHistory()

    const handleCreate = () => {
        history.push("/admin/example/userProfile/create")
    };

    return(
        <div className={classes.root}>
            <Grid>
            <Grid item>
                <Grid 
                    container
                    justify="space-between" 
                    className={classes.subHeader}>
                    <Grid item>
                        <Typography variant="h5" color="textPrimary">
                            User Profiles
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                        variant="contained"
                        color="primary"
                        onClick={handleCreate}>
                            Create User Profile
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item>
                
            </Grid>
            <Grid>
                <GridList>
                    <UserProfileList/>
                </GridList>
            </Grid>
            
        </Grid>
        </div>
    )
}

export default UserProfileOverview