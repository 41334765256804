import * as React from 'react'
import { Drawer, Divider, ListSubheader, MenuItem, ListItemIcon, List, ListItemText, CssBaseline, CircularProgress } from '@material-ui/core';
import { Link, BrowserRouter, useHistory } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import Base from './BaseLayout';
import AdminRouter, { AdminDashboardRoutes, AdminDashboardExampleRoutes } from '../../router/AdminRouter';
import HeaderAdmin from '../header/HeaderAdmin'
import { RouteConfig } from '../../router/RouteConfig';
import { FunctionComponent } from 'react';
import { useAuthenticated } from '../hoc/useLogin';

type Props = {}

const AdminLayout :FunctionComponent<Props> = (props) => {

    const classes = useStyles();
    const history = useHistory()
    const autheticated = useAuthenticated()

    const activeRoute = (routeName: any) => {
        return history.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    
    const renderSection = (routes: Array<RouteConfig>, sectionName: string) => {
        
        return (
            <List
                subheader={<ListSubheader component="div">{sectionName}</ListSubheader>}
            >
                {routes.map((prop, key) => {
                    return (
                        <Link to={prop.path} style={{ textDecoration: 'none' }} key={key}>
                            <MenuItem dense selected={activeRoute(prop.path)}>
                                <ListItemIcon>
                                    <prop.icon className={classes.icon}/>
                                </ListItemIcon>
                                <ListItemText primary={prop.sidebarName} classes={{ primary: classes.listItemText }} />
                            </MenuItem>
                        </Link>
                    );
                })}
            </List>
        )
    }

    return (
        <div>
            {autheticated.authenticated === true ? (
                <Base header={HeaderAdmin}>
                <CssBaseline />
                <div className={classes.root}>
                <BrowserRouter>
                
                    <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            anchor="left"
                            //open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                    >
                        <Divider />
                            {renderSection(AdminDashboardRoutes, "Main")}
                        <Divider />
                            {renderSection(AdminDashboardExampleRoutes, "Examples")}
                        <Divider />
                        
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <AdminRouter />
                    </main>
                </BrowserRouter>
                </div>
            </Base>
            ) :(
                <CircularProgress/>
            )}
        </div>
        
    )
    
}
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 75,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        //backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    icon: {
        marginRight: 8
    },
    listItemText: {
        //fontSize: '0.7em',//Insert your required size
        color: 'black'
    }
  }),
);

export default AdminLayout
/*
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 75,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        //backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    icon: {
        marginRight: 8
    },
    listItemText: {
        //fontSize: '0.7em',//Insert your required size
        color: 'black'
    }
});



export default withRouter(withStyles(styles, { withTheme: true })(AdminLayout))
*/