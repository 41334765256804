import React, {FunctionComponent} from 'react';
import {Paper} from '@material-ui/core';
import LinearProgress from "@material-ui/core/LinearProgress";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHalResource} from '../../components/hoc/withHalResource';
import {HalResource} from '../../model/common/HalResource';
import {ResourceName} from '../../model/common/ResourceName';
import {Status} from '../../components/hoc/withHalContext';

type Props = {
    resourceName: ResourceName,
    resourceParams?: any,
    mapRowToComponent: (row: HalResource) => any
}

/*
* Provides a way of rendering a list of components
* pass the resource name for rendering the list
* pass a function to map the resource -> individual component
*
*/
const FetchListAndRender: FunctionComponent<Props> = (props) => {

    const classes = useStyles();

    const formDefFetchResult = useHalResource({resourceName: props.resourceName, resourceParams: props.resourceParams});

    const getData = (): Array<HalResource>  => {
        const maybe = formDefFetchResult.resource;
        return maybe ? maybe.map(res => res.resource['content'] as Array<HalResource>).getOrElse([]) : []
    };

    return (
        <Paper className={classes.root}>
            {formDefFetchResult.status === Status.LOADING && (<LinearProgress />)}
            {formDefFetchResult.status === Status.ERROR ? (
                <div>
                    ERROR LOADING
                </div>
                ) : (
                getData().map((row, index) => (
                    props.mapRowToComponent(row)
                ))
            )}
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        padding: '20px 20px;',
        width: '100%',
    }
  }),
);

export default FetchListAndRender