import * as React from 'react';
import { useStore } from '../../model/store/StoreProvider';
import RootStore from '../../model/store/RootStore';

export type WithStoreContextProps = {
    rootStore: RootStore;
}

/*
* HOC to provide the halStore as a prop on the wrapped component
*/
export const withStoreContext = (Component: any) => {
    return (props: any) => {
      const rootStore = useStore()
      return <Component rootStore={rootStore} {...props} />;
    };
};