import React, {FunctionComponent} from 'react';
import FormBuilderWrapper from '../../../../components/form-builder/FormBuilder';
import {ResourceName} from '../../../../model/common/ResourceName';
import {useHistory} from 'react-router';
import {Maybe} from '../../../../model/common/Maybe';
import {HalResource} from '../../../../model/common/HalResource';
import {Grid, IconButton, makeStyles, Tooltip} from '@material-ui/core';
import {createStyles} from '@material-ui/core/styles';
import {CrudMode} from '../../../../model/common/Common';
import {useMutableHalResource} from '../../../../components/hoc/withMutateHalResource';
import FormEditOptions from '../../../../components/form-builder/FormEditOptions';
import CloseIcon from '@material-ui/icons/Close';
import {useDeleteHalResource} from "../../../../components/hoc/withDeleteHalResource";

type Props = {
    mode: CrudMode,
    resource: Maybe<HalResource>
}

const FormBuilder: FunctionComponent<Props> = (props : Props) => {

    const classes = useStyles()
    const history = useHistory()

    const handleClose = () => {
        history.goBack()
    }

    const mutationHook = useMutableHalResource({ 
        createdResourceName: ResourceName.CREATE_FORM_DEFINITION,
        updatedResourceName: ResourceName.FORM_DEFINITION,
        postSubmit: handleClose
    })

    const deleteHook = useDeleteHalResource({postDelete: handleClose})

    const getFormDef = (): any => {
        return props.resource.map(res => res.properties).getOrElse({} as any)
    }

    const onSubmit = (form: any) => {
        if (props.mode === CrudMode.CREATE) {
            mutationHook.handleMutation(form, props.mode)
        } else {
            //attempting to update resource
            const link = props.resource.map(resource => resource.links && resource.links.get('self'))
            console.log('extracted link ', link)
            link.map(
                url => mutationHook.handleMutation(form, props.mode, url)
            )
        }
    }

    const onSave = (data: any) => {
        console.log('on save ', data)
    }

    const onChange = (data: any) => {
        console.log('on change ', data)
    }

    const handleVersionStatus = () => {
        const linksAsMap = props.resource.map(resource => resource.links).getOrElse({} as any)
        let formPublishActionLink = linksAsMap?.get(ResourceName.FORM_DEFINITION_ACTION_PUBLISH);
        console.log("handle update publish/versionStatus we have link ", linksAsMap);
        mutationHook.handleMutation(null, CrudMode.UPDATE, formPublishActionLink)
    }

    const handleDelete = () => {
        const link = props.resource.map(resource => resource.links && resource.links.get('self'))
        console.log('extracted link ', link)
        link.map(
            url => deleteHook.handleDelete(ResourceName.FORM_DEFINITION, url)
        )

    }

    return (
        <Grid>
            <Grid container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  className={classes.header}
            >
                <Grid>
                    <h3>Form Builder</h3>
                </Grid>
                <Grid>
                    <FormEditOptions
                        onDelete={handleDelete}
                        onPublish={handleVersionStatus}
                    />
                    <Tooltip title="Close form edit without saving...">
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item>
                <FormBuilderWrapper
                    onChange={onChange}
                    onSaveComponent={onSave}
                    onSaveForm={onSubmit}
                    form={getFormDef()}
                />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(
    createStyles({
        header: {
            paddingBottom: '50px'
        }
    })
)

export default FormBuilder

/*
LEAVING THIS HERE TO SHOW AS AN EXAMPLE OF HOW CLASS BASE LOOKS
type Props = {
    classes?: any,
    rootStore?: RootStore,
    mode: CrudMode,
    resource: Maybe<HalResource>
} & RouteComponentProps


@observer
@inject('rootStore')
class FormBuilder extends React.Component<Props>{

    onSave = (data: any) => {
        console.log('on save ', data)
    }

    onChange = (data: any) => {
        console.log('on change ', data)
    }

    onSaveForm = (form: any) => {
        console.log('on save form ', form, this.props.mode)
        if (this.props.rootStore) {
            if (this.props.mode === CrudMode.CREATE) {
                this.props.rootStore.halStore.createResource(ResourceName.CREATE_FORM_DEFINITION, form)
            } else {
                //attempting to update resource
                const link = this.props.resource.map(resource => resource.links && resource.links.get('self'))
                console.log('extracted link ', link)
                link.map(
                    url => this.props.rootStore && this.props.rootStore.halStore.updateResourceFromUrl(ResourceName.FORM_DEFINITION, url, form)
                )
            }
           
            this.waitForResponse()
        }
    }

    waitForResponse() {
        when(
            // once...
            () => this.checkCallBack,
            // ... then
            () => this.doNavigate()
        )
    }
    
    @computed get checkCallBack(): boolean {
        if (this.props.rootStore) {
            const resourceName = this.props.mode === CrudMode.CREATE ? ResourceName.CREATE_FORM_DEFINITION : ResourceName.FORM_DEFINITION
            const resp = this.props.rootStore.halStore.resources.get(resourceName)
            console.log('we have response ', resp)
            return resp ? resp.map(res => true).getOrElse(false) : false
        }
        return false
    }  

    doNavigate = () => {
        this.props.history.push("/admin/formBuilder")
    }

    handleClose = () => {
        this.props.history.push("/admin/formBuilder")
    }

    render() {
        
        const form = this.props.resource.map(resource => resource.properties).getOrElse({} as any)

        return (
            <Grid>
                <Grid item>
                    <SubHeader
                        title="Form Builder"
                        handleClose={this.handleClose}
                    />
                </Grid>
                <Grid item>
                    <FormBuilderWrapper
                        onChange={this.onChange}
                        onSaveComponent={this.onSave}
                        onSaveForm={this.onSaveForm}
                        form={form}
                    />
                </Grid>
            </Grid>
            
        )
    }
}

export default withRouter(FormBuilder)
*/