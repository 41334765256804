import { HalResource } from "./HalResource";

export type FormSummaryInfo = {
    formType: string,
    totalSteps: number,
    totalFields: number
}

export type FormSummaryData = {
    formName: string,
    version: string,
    versionStatus: string,
    uuid: string,
    id: string,
    created: string,
    updated: string,
    link: any,
    formSummary: FormSummaryInfo
}

export const mapRowToType = (row: HalResource): FormSummaryData => {
    return {
        link: row.links,
        id: row.identity as string,
        uuid: row['uuid'],
        formName: row.properties['name'],
        versionStatus: row['versionStatus'],
        version: row['source']['version'],
        created: row['createdTime'],
        updated: row['updatedTime'],
        formSummary: row.properties['formSummary']
    }
}