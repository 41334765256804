import React, {FunctionComponent} from 'react';

import {ResourceName} from '../../../model/common/ResourceName';
import {AppBar, Toolbar, Typography, Tooltip, Fab} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {useHalResourcePassState} from "../../../components/hoc/withHalResourcePassState";

import { bindMetaData } from '../multi-form/mapping/MultiFormMapping';
import MultiFormDashboardSummary from '../multi-form/MultiFormDashboardSummary';
import { RenderMode, TilePropsNoActions } from '../form-builder/list/FormItemWithAction';
import { mapRowToType } from '../../../model/common/ResourceDataTypes';
import { HalResource } from '../../../model/common/HalResource';
import { ResourceBuilder } from '../../../model/common/ResourceBuilder';
import { useHistory } from 'react-router';

const MultiFormCaptureHeader: FunctionComponent = (prop) => {

    const classes = useStyles()
    const history = useHistory()

    const multiFormDefinition = useHalResourcePassState({
        resourceName: ResourceName.MULTI_FORM_DEFINITION_SECTIONS, 
        resourceParam: 'multiFormUrl'
    });

    const mapMetaData = () => {
        return bindMetaData(multiFormDefinition.resource)
    }

    const withTileProps = (res: HalResource): TilePropsNoActions => {
        console.log('resource ', res.resource)
        return {
            status: multiFormDefinition.status,
            data: mapRowToType(res.resource),
            renderMode: RenderMode.NORMAL
        }
    }
    
    const handleCreateFormCapture = () => {
        const linksAsMap = multiFormDefinition.resource.map(res => ResourceBuilder.transform(res.resource.links));
        const formActionLink = linksAsMap.map(map => map.get(ResourceName.MULTI_FORM_DEFINITION_SECTIONS));

        formActionLink.map(url => 
            history.push({
                pathname: "/admin/formBuilder/multi/preview",
                state:{
                    resourceUrl: url
                }
            })
        )
    }

    return (
        <div>
        <AppBar position="static" color="default">
            <Toolbar>
                <Typography variant="h5">
                    Multi Form Captures - {mapMetaData().getFormName()} 
                </Typography>
                <Tooltip title={"Create new " + mapMetaData().getFormName()}>
                    <Fab size="small" aria-label="Add" onClick={handleCreateFormCapture} className={classes.fab} color="primary">
                        <AddIcon />
                    </Fab>
                </Tooltip> 
            </Toolbar>
            {multiFormDefinition.resource.map(res => 
                                 <MultiFormDashboardSummary 
                                 {...withTileProps(res)} />
                                )
                                .getOrElse(<React.Fragment/>)
                    }  
            
        </AppBar>
        
        </div>
    )
};


const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        fab: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: "primary"
        }
}));

export default MultiFormCaptureHeader