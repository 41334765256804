import * as React from 'react';
import { useHalStore } from '../../model/store/StoreProvider';
import HalStore from '../../model/store/HalStore';

export enum Status {
    INIT = "INIT",
    LOADING = "LOADING",
    LOADED = "LOADED",
    ERROR = "ERROR"
}

export type WithHalContextProps = {
    halStore: HalStore;
}

/*
* HOC to provide the halStore as a prop on the wrapped component
*/
export const withHalContext = (Component: any) => {
    return (props: any) => {
      const halStore = useHalStore()
      return <Component halStore={halStore} {...props} />;
    };
};