import React, { FunctionComponent } from 'react';
import { Maybe } from '../../../../model/common/Maybe';
import { CrudMode } from '../../../../model/common/Common';
import MultiFormDefinition from './MultiFormDefinition';

const CreateMultiFormDefinition : FunctionComponent = () => {

        console.log('ddddddddd')
        
        return (
            <MultiFormDefinition
                resource={Maybe.none()}
                crudMode={CrudMode.CREATE}
            />
        )
}

export default CreateMultiFormDefinition