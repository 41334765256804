/*
* MAPS TO RESOURCE NAMES PROVIDED BY THE API
*/
export enum ResourceName {
    PLATFORM_HOME = "PLATFORM_HOME",
    FORM_CAPTURE = "FORM_CAPTURE",
    ALL_FORM_CAPTURES = "ALL_FORM_CAPTURES",
    FORM_CAPTURE_BY_ARTIFACT = "FORM_CAPTURE_BY_ARTIFACT",
    MULTI_FORM_DEFINITION_CAPTURE_DATA = "MULTI_FORM_DEFINITION_CAPTURE_DATA",
    CREATE_FORM_CAPTURE = "CREATE_FORM_CAPTURE",
    CREATE_MULTI_FORM_CAPTURE = "CREATE_MULTI_FORM_CAPTURE",
    CREATE_FORM_DEFINITION = "CREATE_FORM_DEFINITION",
    FORM_DEFINITION = "FORM_DEFINITION",
    ALL_FORM_DEFINITIONS = "ALL_FORM_DEFINITIONS",
    FORM_DEFINITION_BY_NAME = "FORM_DEFINITION_BY_NAME",
    FORM_DEFINITION_ACTION_PUBLISH = "FORM_DEFINITION_ACTION_PUBLISH",
    FORM_DEFINITION_ACTION_REDRAFT = "FORM_DEFINITION_ACTION_REDRAFT",
    FORM_DEFINITION_ACTION_ARCHIVE = "FORM_DEFINITION_ACTION_ARCHIVE",
    CREATE_MULTI_FORM_DEFINITION = "CREATE_MULTI_FORM_DEFINITION",
    MULTI_FORM_DEFINITION = "MULTI_FORM_DEFINITION",
    MULTI_FORM_DEFINITION_ACTION_PUBLISH = "MULTI_FORM_DEFINITION_ACTION_PUBLISH",
    MULTI_FORM_DEFINITION_ACTION_REDRAFT = "MULTI_FORM_DEFINITION_ACTION_REDRAFT",
    MULTI_FORM_DEFINITION_ACTION_ARCHIVE = "MULTI_FORM_DEFINITION_ACTION_ARCHIVE",
    MULTI_FORM_DEFINITION_SECTIONS = "MULTI_FORM_DEFINITION_SECTIONS",
    ALL_MULTI_FORM_DEFINITIONS = "ALL_MULTI_FORM_DEFINITIONS"
}