import React, {FunctionComponent, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Button, IconButton, Paper, Step, StepLabel, Stepper, Tooltip, Typography} from '@material-ui/core';
import {useHistory} from 'react-router';

import {bindFormCaptureData, bindMetaData} from '../mapping/MultiFormMapping';
import CloseIcon from '@material-ui/icons/Close';
import SectionStep from './SectionStep';

import {HalResource} from '../../../../model/common/HalResource';
import {Maybe} from '../../../../model/common/Maybe';
import {useTranslation} from 'react-i18next';
import {useMutableHalResource} from "../../../../components/hoc/withMutateHalResource";
import {ResourceName} from "../../../../model/common/ResourceName";
import {CrudMode} from "../../../../model/common/Common";
import {mapToJSON} from "../../../../model/common/ResourceBuilder";
import WizardSectionStep from './WizardSectionStep';
import { bindSectionMetaData } from '../mapping/SectionFormMapping';

type Props = {
    formDefinition: Maybe<HalResource>
    crudMode: CrudMode,
    data: Maybe<HalResource>
}

const MultiFormHorizontalStepper: FunctionComponent<Props> = (props) => {

    const classes = useStyles();
    const history = useHistory()
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [overallActiveStep, setOverallActiveStep] = useState(0);
    const [sectionSubmitFn, setSectionSubmitFn] = useState<any>();
    const [showNext, setShowNext] = useState<boolean>(false)
    const [showPrevious, setShowPrevious] = useState<boolean>(true)

    const formCaptureData = () => {
        return bindFormCaptureData(props.data)
    }
    const [formData, setFormData] = useState<Map<string,any>>(formCaptureData().getFormCaptureData())

    const doNavigation = () => {
        console.log('post handle submit called ')
        history.push("/admin/formBuilder/multi/list")
    }

    const mutationHook = useMutableHalResource({
        createdResourceName : ResourceName.CREATE_MULTI_FORM_CAPTURE,
        updatedResourceName : ResourceName.FORM_CAPTURE,
        postSubmit: doNavigation
    })
    const formMetaData = () => {
        return bindMetaData(props.formDefinition)
    }
    
    const sectionFormMetaData = () => {
        return bindSectionMetaData(props.formDefinition)
    }

    const stepLabels = (): Array<string> => {
        const tabConfig = sectionFormMetaData().getSectionArray()
        let tabs = tabConfig.map(tab => tab.title)
        tabs.push('confirmation')
        return tabs
    }

    const bindSectionSubmitFn = (childSubmit: any) => {
        console.log('form submit called ', childSubmit)
        setSectionSubmitFn(() => childSubmit)
    }

    const onSectionSubmit = (data: any, index: number) => {
        console.log('existing data ', formData)
        let existingData = formData
        existingData.set("section" + index, data)
        console.log('updated data ', existingData )
        setFormData(existingData)
        //only move to next tab if we have submiited data, ie no errors
        setActiveStep(activeStep + 1);
        setOverallActiveStep(overallActiveStep + 1);
    }

    const showNextButton = (step: number): boolean => {

        const formSummary = sectionFormMetaData().getSectionFormSummary(step)
        if (formSummary && formSummary.formType && formSummary.formType === 'wizard') {
            return showNext
        } else {
            return activeStep < stepLabels().length - 1
        }
    }

    const showPreviousButton = (step: number): boolean => {

        const formSummary = sectionFormMetaData().getSectionFormSummary(step)
        if (formSummary && formSummary.formType && formSummary.formType === 'wizard') {
            return showPrevious
        } else {
            return activeStep !== 0 
        }
    }

    const showSubmitButton = (step: number): boolean => {
        return activeStep === (stepLabels().length-1)
    }

    const onSectionNavigate = (sectionActiveStep: number, sectionTotalSteps: number, navigateBackForward: number) => {

        console.log('onSectionNavigate ', sectionActiveStep, sectionTotalSteps, navigateBackForward)
        const showNext = sectionActiveStep === (sectionTotalSteps - 1)
        const showPrevious = sectionActiveStep === 0
        setShowNext(showNext)
        setShowPrevious(showPrevious)
        setOverallActiveStep(overallActiveStep + navigateBackForward)
    }

    const stepContent = (step: number): any => {
        
        if (step < (stepLabels().length-1))  {

            const formSummary = sectionFormMetaData().getSectionFormSummary(step)
            const key = sectionFormMetaData().sectionFormDefinition(step).identity
            console.log('we have key ', key)
            if (formSummary.formType && formSummary.formType === 'wizard') {
                return <WizardSectionStep
                    key={key}
                    sectionIndex={step}
                    doSubmit={bindSectionSubmitFn}
                    formDefinition={sectionFormMetaData().sectionFormDefinitionProps(step)}
                    data={formData.get("section" + step)}
                    onSubmit={onSectionSubmit}
                    onNavigateStep={onSectionNavigate}
                />
            } else {
                return <SectionStep
                    key={key}
                    sectionIndex={step}
                    doSubmit={bindSectionSubmitFn}
                    formDefinition={sectionFormMetaData().sectionFormDefinitionProps(step)}
                    data={formData.get("section" + step)}
                    onSubmit={onSectionSubmit}
                />
            }
               
        } else {
                return <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                    {t("confirmation")}
                    </Typography>
                    
                    <Typography variant="subtitle1">
                        All good ?
                    </Typography>
                </React.Fragment>
        }
    }

    const handleClose = () => {
        history.goBack()
    }

    const handleBack = (event: any) => {
        event.preventDefault()
        setActiveStep(activeStep - 1);
        setOverallActiveStep(overallActiveStep -1)
        setShowNext(false)
    };

    const handleNext = (event: any) => {
        event.preventDefault()
        //console.log('handle next called current step ', activeStep)
        //console.log('handle next called ', event)
    
        if (sectionSubmitFn) {
            //console.log('handleNext form error before submit ')
            sectionSubmitFn(event)
            //console.log('form error after submit ', state.formValid)
        }
    };

    const handleSubmit = (event: any) =>  {

        const formDef = props.formDefinition.getOrElse({} as any)

        const payload = {
            form: formDef,
            data: mapToJSON(formData)
        }
        console.log('Submitting data ', formData)
        if (props.crudMode === CrudMode.CREATE) {
            mutationHook.handleMutation(payload, props.crudMode)
        } else {
            //attempting to update resource
            const link = props.data.map(resource => resource.links && resource.links.get('self'))
            console.log('extracted link ', link)
            link.map(
                url => mutationHook.handleMutation(payload, props.crudMode, url)
            )
        }
        handleClose()
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.header}>
                <Typography component="h1" variant="h5" align="center">
                    {t(formMetaData().getFormName())}
                </Typography>
                <Tooltip title="Close form edit without saving...">
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                </Tooltip>
            </div>
            <div>
                <Typography component="h1" variant="subtitle1" align="center">
                    Total Fields - {formMetaData().getFormSummary().totalFields}
                </Typography>
                <Typography component="h1" variant="subtitle1" align="center">
                    Progress - {overallActiveStep} / {formMetaData().getFormSummary().totalSteps}
                </Typography>
            </div>

            <Stepper activeStep={activeStep} className={classes.stepper}>
                {stepLabels().map(label => (
                <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <form>
                {stepContent(activeStep)}
                <div className={classes.buttons}>
                    {showPreviousButton(activeStep) && (
                        <Button onClick={handleBack} className={classes.button}>
                            {t('previous')}
                        </Button>
                    )}
                    {showNextButton(activeStep) && 
                    (
                        <div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                 {t('next')}
                            </Button>
                        </div>
                    )}
                    {showSubmitButton(activeStep) && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.button}
                            >
                             {t('submit')}
                        </Button>
                    )}
                </div>
            </form>             
        </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
              width: 600,
              marginTop: theme.spacing(6),
              marginBottom: theme.spacing(6),
              padding: theme.spacing(3),
            },
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        stepper: {
            padding: theme.spacing(3, 0, 5),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
    }),
);

export default MultiFormHorizontalStepper