import * as React from 'react'
import { FunctionComponent } from 'react';
import { useErrorResource } from '../hoc/withErrorResource';
import { Snackbar, IconButton, Theme, makeStyles, SnackbarContent, Slide } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Close } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions/transition';

type Props = {
    header: any,
} 

const useStyles = makeStyles((theme: Theme) => ({
    error: {
        backgroundColor: 'red',
    },
}));

const Base: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const errorHook = useErrorResource({})
    const [open, setOpen] = React.useState(false);

    const getError = () => {
        const errorResult = errorHook.checkHaveErrors()
        //console.log('we have errors ', errorResult.errorResource)
        errorResult.errorResource.map(err => console.log('actual error', err))
        if (errorResult.haveErrors === true && open ===false) {
            setOpen(errorResult.haveErrors)
        }
        return errorResult.haveErrors
    }

    const getErrorMessage = () => {
        const errorResult = errorHook.checkHaveErrors()
        return errorResult.errorResource.map(err => err.message).getOrElse('Network Error!')
    }

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
        errorHook.clearErrors()
    };

    const SlideTransition = (props: TransitionProps) => (<Slide {...props} direction="down" />)

    return (
        <div className="Base">
                <props.header/>
            <div className="main">
                {props.children}
            </div>
            <div>
                {getError()}
                <Snackbar 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={open} 
                    autoHideDuration={6000} 
                    onClose={handleClose}
                    aria-describedby="client-snackbar"
                    TransitionComponent={SlideTransition}
                    >
                    <SnackbarContent 
                        className={classes.error}
                         message={
                            <span id="client-snackbar" className={classes.error}>
                            <ErrorIcon/>
                                {getErrorMessage()}
                            </span>
                        }
                        action={
                            <React.Fragment>
                              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <Close fontSize="small" />
                              </IconButton>
                            </React.Fragment>
                          }
                    >
                    </SnackbarContent>
                </Snackbar>
                
            </div>
        </div>
    )
}

export default Base