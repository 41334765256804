import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, GridList, Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import FormList from "./FormList";

type Props = {
    classes: any
} & RouteComponentProps

class FormSummary extends React.Component<Props> {

    handleCreate = () => {
        this.props.history.push("/admin/formBuilder/create")
    };

    handleCreateParent = () => {
        this.props.history.push("/admin/formBuilder/parent/create")
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                 <Grid>
                   <Grid item>
                       <Grid 
                           container
                           justify="space-between" 
                           className={classes.subHeader}>
                           <Grid item>
                                <h3>Form Definitions</h3>
                           </Grid>
                           <Grid item>
                              <Button 
                               variant="contained"
                               color="primary"
                               onClick={this.handleCreate}>
                                  Create Form
                              </Button>
                              <Button 
                               variant="contained"
                               color="primary"
                               onClick={this.handleCreateParent}>
                                  Create Multi Step Form
                              </Button>
                           </Grid>
                       </Grid>

                   </Grid>
                   <Grid item>
                       
                   </Grid>
                   <Grid>
                   <GridList>
                       <FormList/>
                   </GridList>
                   </Grid>
                   
               </Grid>
            </div>
        )
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        padding: '50px 24px;',
        width: '100%',
    },
    subHeader: {

    }
});

export default withStyles(styles, { withTheme: true })(FormSummary)
    
