import TransportLayer from '../TransportLayer';
import HalStore from './HalStore';
import LocalStore from './LocalStore';
import AuthStore from './AuthStore';

export default class RootStore{

    halStore: HalStore;
    localStore: LocalStore;
    authStore: AuthStore;
    transportLayer: TransportLayer = new TransportLayer();
  
    constructor() {
        console.log('creating root store')
        this.halStore = new HalStore(this.transportLayer);
        this.localStore = new LocalStore() 
        this.authStore = new AuthStore()
    }
}