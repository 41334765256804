import React, {FunctionComponent} from "react";
import {useHalResourcePassState} from "../../../../components/hoc/withHalResourcePassState";
import {ResourceName} from "../../../../model/common/ResourceName";
import {Status} from "../../../../components/hoc/withHalContext";
import MultiFormHorizontalStepper from "./MultiFormHorizonalStepper";
import {LinearProgress} from "@material-ui/core";
import {Maybe} from "../../../../model/common/Maybe";
import {CrudMode} from "../../../../model/common/Common";
import {HalResource} from "../../../../model/common/HalResource";

type Props = {}

const UpdateMultiFormTemplate: FunctionComponent<Props> = (props) => {

    const formCaptureResult = useHalResourcePassState({resourceName: ResourceName.FORM_CAPTURE, resourceParam: 'formCaptureUrl'})

    const getData = (): Maybe<HalResource> => {
        return formCaptureResult.resource
    }

    const multiFormDefFetchResult = useHalResourcePassState({resourceName: ResourceName.MULTI_FORM_DEFINITION_SECTIONS})

    return (
        <div>
               {multiFormDefFetchResult.status === Status.LOADED ? (
                   <MultiFormHorizontalStepper 
                        formDefinition={multiFormDefFetchResult.resource}
                        data={getData()}
                        crudMode={CrudMode.UPDATE}
                    />
               ) : (
                   <LinearProgress/>
               )}
        </div>
     
    )
}

export default UpdateMultiFormTemplate