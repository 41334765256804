import * as React from 'react'
import { RouteComponentProps, useHistory } from 'react-router';
import { AppBar, Toolbar, IconButton, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

import i18n from '../../i18n';
import RootStore from '../../model/store/RootStore';
import logo from '../../assets/images/sedex-logo.svg';
import { FunctionComponent } from 'react';
import { useStore } from '../../model/store/StoreProvider';

type Props = {
    classes: any,
    rootStore?: RootStore
} & RouteComponentProps

const HeaderAdmin: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const store = useStore()
    
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        store.localStore.setCurrentLanguage(lng)
    };

    const getCurrentLanguage = () => {
        return store.localStore.currentLanguage
    }

    const handleChange = (event:any) => {
    
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        changeLanguage(value)
    };

    const handleHomeLink = (event: any) => {
        history.push("/")
    };

    return (
        <header>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        //onClick={handleDrawerToggle}
                        //className={classes.menuButton}
                        >
                        <img alt="Logo" src={logo} height={20} onClick={handleHomeLink}/>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Connect Forms Admin
                    </Typography>
                    <div className={classes.language}>
                        <Translate className={classes.icon}/>
                        <FormControl className={classes.formControl}>
                                <Select
                                classes={{
                                    root: classes.formControl,
                                    icon: classes.formControl
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={getCurrentLanguage()}
                                onChange={handleChange}>
                                    <MenuItem value={'en'}>English</MenuItem>
                                    <MenuItem value={'de'}>German</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
                </Toolbar>
            </AppBar>

        </header>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        appBar: {
            backgroundColor: 'black',
            color: '#fff',
            zIndex: theme.zIndex.drawer + 1,
            //width: `calc(100% - ${drawerWidth}px)`,
            //marginLeft: drawerWidth,
        },
        root: {
            display: 'flex',
        },
        grow: {
            flexGrow: 1,
        },
        icon: {
            marginRight: 8
        },
        language: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        formControl: {
            color: 'white'
        },
        homeButtonRight: {
            marginLeft: 'auto',
            color: 'white'
        },
    }),
);

export default HeaderAdmin