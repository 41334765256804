import { HalResource } from "./HalResource";

export class ResourceBuilder {

    public static createHalResource(response: any, isError: boolean = false): HalResource {
        //console.log('attempting to create hal resource from ', JSON.stringify(response))
        return new HalResource(
          response,
          response.identity,
          response.properties,
          response._embedded,
          ResourceBuilder.transform(response.links),
          isError
        );
    }

    public static transform(object : any) : Map<string, any> {
        let map = new Map();
        if (object) {
            for (let k of Object.keys(object)) {
                //console.log('setting value ', object[k]['rel'], object[k]['href'])
                map.set(object[k]['rel'], object[k]['href']);
            }
        }
        return map;
    }
}

export const mapToJSON = (map: Map<string, any>) => {
    var object = { };
    for (let [key, value] of map) object[key] = value;
    return object;
}

export const jsonToMap = (object : any) : Map<string, any> =>  {
    
    let map = new Map();
    if (object) {
        for (let [key, value] of Object.entries(object)) {
            //console.log(`${key}: ${value}`);
            map.set(key, value)
        }
    }

    return map;
}
