import React from 'react';
import FormBuilder from './FormBuilder';
import { Maybe } from '../../../../model/common/Maybe';
import { CrudMode } from '../../../../model/common/Common';

type Props = {

} 

class CreateFormDefinition extends React.Component<Props> {

    render() {
       
        return (
            <FormBuilder
                resource={Maybe.none()}
                mode={CrudMode.CREATE}
            />
        )
    }
}

export default CreateFormDefinition