import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import FormRenderer from '../../components/form-renderer/FormRenderer';

import wizard from '../../forms/wizard.json'

import { Paper, Typography, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import RootStore from '../../model/store/RootStore';


type Nullable<T> = T | null;

const sectionData :Record<string, any> = {
    section1 : Object.assign({}, {}),
    section2 : Object.assign({}, {}),
}

type Props = {
    classes: any,
    rootStore?: RootStore
} & RouteComponentProps

type State = {
    activeStep: number,
    language: string,
    data1: any,
    data2: any,
    sectionData: Record<string, any>
}

const getInitialState = () => {
  return {
    activeStep: 0,
    language: 'en',
    data1: {},
    data2: {},
    sectionData: sectionData
  }
}

const steps = ['Section 1', 'Section 2', 'Confirmation'];

@inject('rootStore')
@observer
class HorizontalStepper extends React.Component<Props, State> {
    childFormSubmit: any;
    //form1: React.MutableRefObject<undefined>;

    constructor(props: Props) {
        super(props)
        this.state = getInitialState()
        //this.form1 = useRef()
    }
    
    getCurrentLanguage = () => {
        return this.props.rootStore && this.props.rootStore.localStore.currentLanguage
    }

    formSubmit = (childSubmit: any) => {
        //console.log('form submit called ')
        
        this.childFormSubmit = childSubmit
    }

    /*
    onError = (error: any) => {
        console.log('we have error ', error)
        this.setState({
            formValid: false
        })
    }
    */

    componentDidMount() {
        //console.log('component did mount called step ', this.state.activeStep)
    }

    setActiveStep = (step: number) => {
        console.log('active step called ', step)
        this.setState({
          activeStep: step
        })
    }

    getStepContent(step: number): any {
        switch (step) {
          case 0:
            return <FormRenderer 
                language={this.getCurrentLanguage()}
                doSubmit={this.formSubmit.bind(this)}
                formDefinition={wizard.forms.section1}
                data={this.state.data1}
                onSubmit={this.onChildSubmit}
            />
          case 1:
            return <FormRenderer 
                language={this.getCurrentLanguage()}
                doSubmit={this.formSubmit.bind(this)}
                formDefinition={wizard.forms.section2}
                data={this.state.data2}
                onSubmit={this.onChildSubmit}
                //form={this.state.form}
                //data={defaultData}
                //onSubmit={this.onSubmit}
        />
          case 2:
            return  <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                        Confirmation
                        </Typography>
                        <Typography variant="subtitle1">
                            All good ?
                        </Typography>
                 </React.Fragment>
          default:
            throw new Error('Unknown step');
        }
    }

    handleSubmit = (event: any) => {
        console.log('submit called ')
    }
  
    onChildSubmit = (data: any) => {
        //TODO figure out which sub form the data refers to 
        //this will only get called when there are no errors, ie we can move to next page here
        console.log('on child submit ', data)

        this.setState({
            data1: data
        } as Pick<State, keyof State>)
        this.setActiveStep(this.state.activeStep + 1);
    }

    handleNext = (event: any) => {
        event.preventDefault()
        console.log('handle next called ', event)
        //this.formSubmit(event)

        if (this.childFormSubmit) {
            console.log('form error before submit ', this.state)
            this.childFormSubmit(event)
            console.log('form error after submit ', this.state)
        }
        
    };
    
    handleBack = (event: any) => {
        event.preventDefault()

        //current state
        console.log('data1 ', this.state.data1)

        this.setActiveStep(this.state.activeStep - 1);
    };

    render() {
        const { classes } = this.props;
        return (
        
                <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" align="center">
                    Basic Questionnaire Wizard
                </Typography>
                <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                    {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {this.state.activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                        Confirmation
                        </Typography>
                        <Typography variant="subtitle1">
                            xxxxxx
                        </Typography>
                    </React.Fragment>
                    ) : (
                    
                        <form
                        //onSubmit={this.handleNext}
                        >
                        {this.getStepContent(this.state.activeStep)}
                        <div className={classes.buttons}>
                        {this.state.activeStep !== 0 && (
                            <Button onClick={this.handleBack} className={classes.button}>
                            Back
                            </Button>
                        )}
                        {this.state.activeStep < steps.length - 1 ? 
                        (
                            <div>

                            <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                            //onSubmit={this.handleNext}
                            className={classes.button}
                            >
                            Next
                            </Button>
                           

                            </div>
                        ) : (
                            <Button
                            variant="contained"
                            color="primary"
                            //onClick={this.handleSubmit}
                            className={classes.button}
                            >
                            Submit
                            </Button>
                        ) }
                        
                
                        </div>
                        </form>
                    
                    
                    )}
                </React.Fragment>
                </Paper>
           
        )
    }
}

const styles = (theme: Theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        width: 600,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
        margin: '0px auto'
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    
  });

export default withRouter(withStyles(styles, { withTheme: true })(HorizontalStepper))