import { FunctionComponent } from "react";
import { useHalResourcePassState } from "../../../../components/hoc/withHalResourcePassState";
import { ResourceName } from "../../../../model/common/ResourceName";
import React from "react";
import { Status } from "../../../../components/hoc/withHalContext";
import MultiFormHorizontalStepper from "./MultiFormHorizonalStepper";
import { LinearProgress } from "@material-ui/core";
import {CrudMode} from "../../../../model/common/Common";
import {Maybe} from "../../../../model/common/Maybe";

type Props = {

}

const CreateMultiFormTemplate: FunctionComponent<Props> = (props) => {

    const multiFormDefFetchResult = useHalResourcePassState({resourceName: ResourceName.MULTI_FORM_DEFINITION_SECTIONS})

    return (
        <div>
               {multiFormDefFetchResult.status === Status.LOADED ? (
                   <MultiFormHorizontalStepper
                        formDefinition={multiFormDefFetchResult.resource}
                        crudMode={CrudMode.CREATE}
                        data={Maybe.none()}
                    />
               ) : (
                   <LinearProgress/>
               )}
        </div>
     
    )
}

export default CreateMultiFormTemplate