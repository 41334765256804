import React from "react";
import { FunctionComponent } from "react";

import FormRenderer from "../../../../components/form-renderer/FormRenderer";
import { HalResource } from "../../../../model/common/HalResource";
import { useStore } from "../../../../model/store/StoreProvider";

type Props = {
    data: any,
    sectionIndex: number,
    doSubmit: any,
    formDefinition: HalResource,
    onSubmit: any
}

const SectionStep: FunctionComponent<Props> = (props) => {

    const rootStore = useStore()

    const getCurrentLanguage = () => {
        return rootStore.localStore.currentLanguage
    }

    const onSectionSubmit = (data: any) => {
        console.log('attempting to submit ', data, props.sectionIndex)
        props.onSubmit(data, props.sectionIndex)
    }

    const getFormDefintion = () => {
        return props.formDefinition
    }

    const getFormData = () => {
        return props.data
    }

    return (

        <div>
              <FormRenderer 
                    language={getCurrentLanguage()}
                    doSubmit={props.doSubmit}
                    formDefinition={getFormDefintion()}
                    data={getFormData()}
                    onSubmit={onSectionSubmit}
                /> 
        </div>
       
    )
}

export default SectionStep