import { FunctionComponent } from "react";
import React from "react";
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import { Chip, Paper, Avatar } from "@material-ui/core";
import TagFacesIcon from '@material-ui/icons/TagFaces';
import DoneIcon from '@material-ui/icons/Done'
import { useTranslation } from "react-i18next";

export enum SelectMode  {
    SINGLE,
    MULTIPLE
}

export type ChipData = {
    key: number,
    label: string,
    selected?: boolean
}

type Props = {
    selectMode?: SelectMode,
    chipData?: Array<ChipData>
    onChange: any,
}

const ChipSelector: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const replaceAt = (array: Array<any>, index: number, value: any)  => {
        const ret = array.slice(0);
        ret[index] = value;
        return ret;
    }

    const [chipData, setChipData] = React.useState<ChipData[]>([
        { key: 1, label: 'Jan', selected: false },
        { key: 2, label: 'Feb', selected: false },
        { key: 3, label: 'March', selected: false },
        { key: 4, label: 'April', selected: false },
        { key: 5, label: 'May', selected: false },
        { key: 6, label: 'June', selected: false },
        { key: 7, label: 'July', selected: false },
        { key: 8, label: 'Aug', selected: false },
        { key: 9, label: 'Sep', selected: false },
        { key: 10, label: 'Oct', selected: false },
        { key: 11, label: 'Nov', selected: false },
        { key: 12, label: 'Dec', selected: false },
    ]);

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    };

    const handleClick = (chipSelected: ChipData) => () => {
        console.info('You clicked the Chip.', chipSelected);
        const updatingChips: Array<ChipData> = Object.assign([], chipData); 
        console.log('existing ', updatingChips)

        if (props.selectMode === SelectMode.SINGLE) {
             //switch everything off first
            updatingChips.forEach(chip => chip.selected = false);
        }
        
        //toggle selected
        if (chipSelected.selected === true) {
            chipSelected.selected = false
        } else {
            chipSelected.selected = true
        }
       
        props.onChange(chipSelected.label)

        setChipData(chips => replaceAt(chips, chipSelected.key-1, chipSelected));
    };

    return (
        <Paper className={classes.root}>
       
            {chipData.map(data => {
                let icon;

                if (data.label === 'React') {
                icon = <TagFacesIcon />;
                }

                return (
                <Chip
                    key={data.key}
                    //avatar={<Avatar>{data.label.substr(0,1)}</Avatar>}
                    icon={icon}
                    label={t(data.label)}
                    color={data.selected === true ? "primary" : "default"}
                    onClick={handleClick(data)}
                    onDelete={handleClick(data)}
                    //onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                    className={classes.chip}
                    deleteIcon={data.selected === true ? <DoneIcon /> : <TagFacesIcon/>}
                />
                );
            })}
        </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: theme.spacing(0.5),
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    })
);

export default ChipSelector