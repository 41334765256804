import React from 'react';

import FormRenderer from './FormRenderer';
import RootStore from '../../model/store/RootStore';
import { withStoreContext, WithStoreContextProps } from '../hoc/withRootStoreContext';

type Props = {
    rootStore?: RootStore,
    onSubmit: any,
    formDefinition: any,
    data: any
} & WithStoreContextProps

class MultiLanguageFormRenderer extends React.Component<Props> {

    getCurrentLanguage = () => {
        return this.props.rootStore.localStore.currentLanguage
    }

    render() {
        return (
                <div>
                    <FormRenderer 
                        language={this.getCurrentLanguage()}
                        formDefinition={this.props.formDefinition}
                        data={this.props.data}
                        onSubmit={this.props.onSubmit}
                    />
                </div>
        )
    }
}

export default withStoreContext(MultiLanguageFormRenderer)


