import React from 'react';
import MultiLanguageFormRenderer from '../../components/form-renderer/MultiLanguageFormRenderer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { computed, when } from 'mobx';

import RootStore from '../../model/store/RootStore';
import { ResourceName } from '../../model/common/ResourceName';

type Props = {
    rootStore: RootStore
} & WithTranslation

@observer
@inject('rootStore')
class QuestionnairePanel extends React.Component<Props> {

    componentDidUpdate() {
        console.log('component did update called ', this.props.rootStore.halStore.resourcesStatuses.get(ResourceName.ALL_FORM_CAPTURES))
    }

    waitForResponse() {
        when(
            // once...
            () => this.checkCallBack,
            // ... then
            () => this.doNavigate()
        )
    }

    onSubmit = (data: any) => {
        console.log('submitted data', data.data)

        this.props.rootStore.halStore.createResource(ResourceName.ALL_FORM_CAPTURES, data.data)
        this.waitForResponse()
    }

    @computed get checkCallBack(): boolean {
        const questionnaire = this.props.rootStore.halStore.resourcesStatuses.get(ResourceName.ALL_FORM_CAPTURES)
        console.log('updated questionnaire ', questionnaire)
        return questionnaire ? true : false
    }

    doNavigate = () => {
        console.log('do navigate called ')
    }

    render() {
        const { t } = this.props
        return (
                <div>

                    <h3>{t('Questionniare')}</h3>
                
                    <div className="App-intro">
                        <MultiLanguageFormRenderer 
                            onSubmit={this.onSubmit}
                        />
                    </div>
                    <div>
                        Updating State ... {this.checkCallBack}
                    </div>
                </div>
        )
    }
}

export default withTranslation() (QuestionnairePanel)


