import React, { FunctionComponent } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { MenuItem, FormControl, InputLabel, Select, ListSubheader, Fab, Grid, TextField } from "@material-ui/core";
import { HalResource } from "../../../../model/common/HalResource";
import RemoveIcon from '@material-ui/icons/Remove';
import { Maybe } from "../../../../model/common/Maybe";
import FormSummarySimpleTile from "../../form-builder/view/FormSummarySimpleTile";
import { mapRowToType } from "../../../../model/common/ResourceDataTypes";
import { FormSummary } from "../mapping/MultiFormMapping";

export type SectionMetaData = {
    identity: string,
    uuid: string,
    title: string,
    formSummary: FormSummary
}

type Props = {
    handleLookupData: (status: string) => Array<HalResource>,
    handleChange: (evt: React.ChangeEvent<{}>) => void,
    resource: Maybe<HalResource>,
    sectionNumber: number,
    sectionData: SectionMetaData,
    removeTab: (evt: React.ChangeEvent<{}>, index: number) => void
}

const SectionFormDefinition: FunctionComponent<Props> = (props : Props) => {
    
    const classes = useStyles()

    const getMenuItem = (row: any) => {
        return (
            <MenuItem key={row.identity} value={row.identity}>
                {row.properties['name'] + " - " + row['source']['version']}
            </MenuItem>
        )
    };
    
    const getListItemDataByStatus = (status: string):Array<HalResource> => {
       return props.handleLookupData(status)
    }
 
    return (
            <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <TextField
                        className={classes.formControl}
                        label="Section Title"
                        name={"section" + props.sectionNumber + ".title"}
                        variant="outlined"
                        value={props.sectionData && props.sectionData.title}
                        onChange={props.handleChange}
                    />
                </Grid>    
                <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">{'Form Definition ' + (1+props.sectionNumber)}</InputLabel>
                        <Select defaultValue="" 
                            value={props.sectionData && props.sectionData.identity}
                            onChange={props.handleChange}
                            inputProps={{
                                name: 'section' + props.sectionNumber +'.identity',
                                id: 'grouped-select',
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        <ListSubheader>Published</ListSubheader>
                            {getListItemDataByStatus('PUBLISHED').map((row, index) => (
                                        getMenuItem(row)
                                    ))
                            }
                        <ListSubheader>Draft</ListSubheader>
                            {getListItemDataByStatus('DRAFT').map((row, index) => (
                                        getMenuItem(row)
                                    ))
                            }
                        
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    {props.resource.map(res => 
                                <FormSummarySimpleTile
                                    data={mapRowToType(res)}
                                />)
                                .getOrElse(<React.Fragment/>)
                    }        
                </Grid>
                <Grid item xs={12}>
                    <Fab 
                        aria-label="Remove" 
                        onClick={(e) => props.removeTab(e, props.sectionNumber)} 
                        className={classes.fab} 
                        color="primary">
                        <RemoveIcon />
                    </Fab>
                </Grid>
            </Grid>
           
           </div>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 300,
        },
        fab: {
            display: 'flex',
            alignItems: 'flex-end',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: "red"
        }
}));

export default SectionFormDefinition