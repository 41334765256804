import React, { FunctionComponent } from 'react';
import { CrudMode } from '../../../../model/common/Common';
import MultiFormDefinition from './MultiFormDefinition';
import { useHalResourcePassState } from '../../../../components/hoc/withHalResourcePassState';
import { ResourceName } from '../../../../model/common/ResourceName';
import { Status } from '../../../../components/hoc/withHalContext';
import { LinearProgress } from '@material-ui/core';

const EditMultiFormDefinition: FunctionComponent = () => {

    const fetchResource = useHalResourcePassState( {resourceName:ResourceName.MULTI_FORM_DEFINITION})

    return (
        <div>
            {fetchResource.status != Status.LOADED ? (
            <LinearProgress/>
        ) : (
            <MultiFormDefinition
                resource={fetchResource.resource}
                crudMode={CrudMode.UPDATE}
            />
        )
        }
        </div>
            
           
    )
}

export default EditMultiFormDefinition