import * as React from 'react'
import { withStyles, createStyles } from "@material-ui/styles";
import { Grid, Typography } from '@material-ui/core';
import hero from './../../../assets/images/hero.jpg'
import HorizontalStepper from "../HorizontalStepper";

type Props = {
    classes: any
}

class Welcome extends React.Component<Props> {

    myRef: React.RefObject<HTMLDivElement>

    constructor(props: Props) {
        super(props)
        this.myRef = React.createRef();
    }

    render() {
        const { classes } = this.props;
     
        return (
            <div className={classes.root}>

                <section className={classes.hero} style={{ paddingBottom: '30%', backgroundImage: `url(${hero})` }} ref={this.myRef}>
                    <span className={classes.HeroDarken} />
                    <div className={classes.heroContent}>
                        <Grid container spacing={3} className={classes.heroGrid}>
                            <div className="js-Hero">
                                <h1>Sedex</h1>
                                <p/>
                                  <Typography variant="subtitle1">
                                    Empowering Responsible Supply Chains.
                                 </Typography>      
                                <p/>
                            </div>
                        </Grid>
                    </div>
                </section>
                <HorizontalStepper />
            </div>
        )
    }
}

const jsStyles = (theme: any) => createStyles({
    root: {
        flexGrow: 1,
        padding: '80px 24px;'
    },
    hero: {
        position: 'relative',
        paddingTop: 150,
        backgroundSize: 'cover',
        paddingBottom: '25%',
        backgroundPosition: 'center'
    },
    heroContent: {
        display: 'flex',
        position: 'absolute',
        paddingTop: 64,
        paddingLeft: 24,
        paddingRight: 24,
    },
    heroGrid : {
        alignContent: 'center'
    }
});

export default withStyles(jsStyles, { withTheme: true })(Welcome)