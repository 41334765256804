import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { GridListTile, Paper, Typography, IconButton, Grid } from '@material-ui/core';
import { HalResource } from '../../../model/common/HalResource';
import Moment from 'react-moment';
import { EditTwoTone, DeleteForever } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { ResourceBuilder } from '../../../model/common/ResourceBuilder';

type Props = {
    resource: HalResource,
    link: any,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        fontSize: '0.7em',
        width: '100%',
        padding: 5,
        margin: 5,
    },
    button: {

    }
  }),
);

const UserProfileSummaryTile: FunctionComponent<Props> = (props) => {
    const classes = useStyles()
    const history = useHistory()

    const handleEditForm = (event: any) => {
          
        console.log('handle edit we have link ', props.link)
        const linksAsMap = ResourceBuilder.transform(props.link)

        history.push({
            pathname:"/admin/example/userProfile/edit", 
            state:{ 
                resourceUrl: linksAsMap.get('self')
            }
        });
    }

    const formCaptureData = () => {
        return props.resource.properties['data']
    }

    return (
        <GridListTile cols={2} className={classes.root}>
            <Paper className={classes.paper}>
              <Grid container direction="row" spacing={0} justify="space-between" alignItems="center">
                <Grid item xs={2}>       
                    <Typography data-testid="formName" variant="body2" color="textPrimary">
                        {formCaptureData()['firstName']} {formCaptureData()['surname']}
                    </Typography>        
                </Grid>
                <Grid item xs={2}>
                    
                    <IconButton 
                        onClick={handleEditForm}
                        className={classes.button} aria-label="refresh">
                        <EditTwoTone/>
                    </IconButton>
                    <IconButton
                        //onClick={handleDeleteForm}
                        className={classes.button} aria-label="refresh">
                        <DeleteForever/>
                    </IconButton>
                   
                </Grid>
                <Grid item xs={2}>       
                    <Typography data-testid="version" variant="body2" color="textPrimary">
                      Version: {props.resource['source']['version']}
                    </Typography>        
                </Grid>
                <Grid item xs={3}>       
                    <Typography data-testid="createdTime" variant="body2" color="textPrimary">
                      Created: <Moment>{props.resource['createdTime']}</Moment>
                    </Typography>
                    <Typography data-testid="updatedTime" variant="body2" color="textPrimary">
                        Updated: <Moment>{props.resource['updatedTime']}</Moment>
                    </Typography>
                </Grid>
              </Grid>
            </Paper>
          </GridListTile>
    )
}

export default UserProfileSummaryTile