import axios from 'axios'
import environment from './Environment';

export default class TransportLayer {

    headers = {
        "Authorization": "Basic " + environment.apiCredentials.getOrElse(''),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json"
    }

    get(url: string, pathParam: string = "", queryParams?: any, ) {

        return axios.get(url + pathParam, {
            params: queryParams,
            headers: this.headers
        });
    }

    post(url: string, body: any) {
        return axios.post(url, body, {
            headers: this.headers
        });
    }

    put(url: string, body: any) {
        return axios.put(url, body, {
            headers: this.headers
        });
    }

    delete(url: string) {
        return axios.delete(url, {
            headers: this.headers
        })
    }
}