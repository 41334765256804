import React, { FunctionComponent } from 'react';
import { UserProfileForm } from './UserProfileForm';
import { Maybe } from '../../../model/common/Maybe';
import { HalResource } from '../../../model/common/HalResource';
import { CrudMode } from '../../../model/common/Common';

type Props = {}

/*
* Will do the job of fetching the form def and fetching the underlying data 
* Passing the form definition and data down to the child UserProfileForm component
*/
const CreateUserProfile: FunctionComponent<Props> = ({}) => {
    
    const getData = (): Maybe<HalResource> => {
        return Maybe.none()
    }

    return (
        <div>
            <UserProfileForm
                crudMode={CrudMode.CREATE}
                data={getData()}
            />
        </div>
    )
}

export default CreateUserProfile