import { observable, action } from 'mobx';
import {HalResource, ErrorResource} from '../common/HalResource';
import { ResourceName } from '../common/ResourceName';
import TransportLayer from '../TransportLayer';
import {ResourceLinks} from '../common/ResourceLinks';
import {MaybeMap} from '../common/SafeMap';
import environment from '../Environment';
import { Maybe } from '../common/Maybe';
import { ResourceBuilder } from '../common/ResourceBuilder';

export default class HalStore {

    private transportLayer: TransportLayer;
    
    @observable private resourceLinks: ResourceLinks = new ResourceLinks();

    @observable resources: MaybeMap<string, HalResource> = MaybeMap.of();

    //TODO WRAPS THIS IN A COMPONENT
    @observable resourcesStatuses: Map<string, boolean> = new Map();

    @observable errors: Array<ErrorResource> = [];

    @action setResource = (resourceKey: string, resource: Maybe<HalResource>) => { this.resources.set(resourceKey, resource); } 

    @action setError = (resourceKey: string, errorResource: ErrorResource) => { this.errors.push(errorResource); } 

    @action clearAllErrors = () => { this.errors = []} 

    constructor(transportLayer: TransportLayer) {
        console.log('configured to use base api url ', environment.baseApiUrl)
        this.transportLayer = transportLayer;
        this.getPlatformHome()
    }

    @action getPlatformHome() { 
        this.httpGetAndAddLinks(environment.baseApiUrl, ResourceName.PLATFORM_HOME)
    }

    @action
    public getResource(resourceKey: ResourceName, pathParams?: any, forceRefresh: boolean = true) {
        console.log(`attempting to get resource for key ${resourceKey}, path ${pathParams}`)
         //clear the cache out first
         if (forceRefresh === true) {
            this.setResource(resourceKey, Maybe.none());
         }
        if (this.resourceLinks.has(resourceKey)) {
            this.resourcesStatuses.set(resourceKey, true);
            let url = this.resourceLinks.get(resourceKey);
            if (url) {
                console.log('have key, attempting get resource for url ' + url);
                this.httpGetAndAddLinks(url, resourceKey, pathParams);
            }
        } else {
            console.log('getResource key not available yet: ' + resourceKey)
            this.setResource(resourceKey, Maybe.none());
            this.resourcesStatuses.set(resourceKey, false);
        }
    }

    @action
    public getResourceFromUrl(resourceKey: ResourceName, url: string, resourceParams?: any, forceRefresh: boolean = true) {
        console.log('attempting to get resource for key ' + resourceKey + ' ,url ' + url + ' resourceParams ' + resourceParams)
        if (forceRefresh === true) {
            console.log('clearing cache for ' + resourceKey + ' ,url ' + url)
            this.setResource(resourceKey, Maybe.none());
        }
        if (url) {
            console.log('have key, attempting get resource for url ' + url);
            this.httpGetAndAddLinks(url, resourceKey, resourceParams);
        }

    }

    @action
    public createResource(resourceKey: ResourceName, body: any) {
        console.log('attempting create resource for key ' + resourceKey);
        //clear the cache out first
        this.setResource(resourceKey, Maybe.none());
        console.log('key map size ' + this.resourceLinks.values.size);
        console.log('we have url ' + this.resourceLinks.get(resourceKey));
        if (this.resourceLinks.has(resourceKey)) {
            let url = this.resourceLinks.get(resourceKey);
            if (url) {
                console.log('have key, attempting create resource ' + resourceKey + ' ,for url ' + url);
                this.httpPost(url, body, resourceKey);
            }
        }
    }

    @action
    public updateResourceFromUrl(resourceKey: ResourceName, url: string, body: any) {
       
        if (url) {
            this.setResource(resourceKey, Maybe.none());
            console.log('have key, attempting update resource ' + resourceKey + ' ,for url ' + url);
            this.httpPut(url, body, resourceKey);
        }
    }
    
    @action
    public createResourceFromUrl(resourceKey: ResourceName, url: string, body: any) {
        console.log('attempting create resource for key ' + resourceKey);
         
        if (url) {
            console.log('have key, attempting create resource ' + resourceKey + ' ,for url ' + url);
            this.httpPost(url, body, resourceKey);
        }
    }

    @action
    public deleteResourceFromUrl(resourceKey: ResourceName, url: string) {
        if (url) {
            this.setResource(resourceKey, Maybe.none());
            console.log('have key, attempting delete resource ' + resourceKey + ' ,for url ' + url);
            this.httpDelete(url, resourceKey);
        }
    }
    
    private getFullUrl = (path: string) => {
        return path;
    }
    
    private httpGetAndAddLinks(url: string, resourceKey: ResourceName, pathParam?: any) {
       
        this.transportLayer.get(this.getFullUrl(url), pathParam)
            .then( response => {
                this.dealWithOk(response, resourceKey, "GET", url)
            })
            .catch(error => {
                this.catchException(error, resourceKey, "GET")
            })
    }

    private httpPost(url: string, body: any, resourceKey: ResourceName) {
        this.transportLayer.post(this.getFullUrl(url), body)
            .then(response => {
                this.dealWithOk(response, resourceKey, "POST", url)
            })
            .catch(error => {
                this.catchException(error, resourceKey, "POST")
            })
    }

    private httpPut(url: string, body: any, resourceKey: ResourceName) {
        console.log('attempting put with url ', url)
        this.transportLayer.put(this.getFullUrl(url), body)
            .then(response => {
                this.dealWithOk(response, resourceKey, "PUT", '')
            })
            .catch(error => {
                this.catchException(error, resourceKey, "PUT")
            })
    }

    private httpDelete(url: string, resourceKey: ResourceName) {
        this.transportLayer.delete(this.getFullUrl(url))
            .then(response => {
                this.dealWithOk(response, resourceKey, "DELETE", url)
            })
            .catch(error => {
                this.catchException(error, resourceKey, "DELETE")
            })
    }

    dealWithOk = (response: any, resourceKey: string, httpMethod: string, url: string ="") => {
        //console.log('HTTP ' + httpMethod + ' we got response ' + JSON.stringify(response.data))
        let resource: HalResource = ResourceBuilder.createHalResource(response.data);
        //console.log('we have url ', url)
        if (url.length > 0) {
            //manually set the link
            this.resourceLinks.set(resourceKey, url);
        }
        this.addNewLinksToMap(resource);
        this.setResource(resourceKey, Maybe.some(resource));
        this.resourcesStatuses.set(resourceKey, false);
    }

    catchException = ((error: any, resourceKey:string, httpMethod: string) => {
        console.log('HTTP ' + httpMethod + ' ERROR ' + JSON.stringify(error))
       
        const errorResource = this.createError(error)
        const errorHalResource = ResourceBuilder.createHalResource(
            errorResource,
            true
        )
        this.setError(resourceKey, errorResource)
        this.resourcesStatuses.set(resourceKey, false);
        this.setResource(resourceKey, Maybe.some(errorHalResource));
        if (error.response) {
            //console.log('HTTP ' + httpMethod + ' ERROR RESPONSE ' + JSON.stringify(error.response))
            console.log('HTTP ' + httpMethod + ' STATUS ' + error.response.status);
        } else {
            //no response code
        }
    })
    
    private addNewLinksToMap(resource: HalResource) {
        
        if (resource && resource.links) {
            
            resource.links.forEach((value, key) => {
                //console.log('chekcking adding new link ' + key + ', value ' + JSON.stringify(value))
                if (!this.resourceLinks.has(key)) {
                    //console.log('adding new link ' + key + ', value ' + JSON.stringify(value))
                    this.resourceLinks.set(key, value);
                    this.resourcesStatuses.set(key, true);
                    this.loadLazyResourceForLink(key, value);
                }
            });
        }
    }

    private loadLazyResourceForLink(key: string, value: string) {
         if (this.resources.has(key)) {
            console.log('we have resource with key in map ' + key);
            if (this.resources.get(key) == null) {
                console.log('we have NOT loaded resource for key ' + key);
                // this.getResource(key);
            } else {
                console.log('we have loaded resource for key ' + key);
            }
        } else {
            console.log('we have NO resource with key in map ' + key);
        }

    }

    //TODO figure this error type
    private createError(error: any): ErrorResource {
        let status = 0
        let message = "Sorry Something went wrong"
        if (error.response) {
            status = error.response.status
            message = error.response.data.message
        } 

        return new ErrorResource(
            error.response,
            status,
            message
        )

    }
} 