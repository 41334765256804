import React, {FunctionComponent} from 'react';
import {HalResource} from '../../../../model/common/HalResource';
import {ResourceName} from '../../../../model/common/ResourceName';
import FetchListAndRender from '../../../../components/common/FetchListAndRender';
import FormItemWithAction, { RenderMode, ActionKeys } from './FormItemWithAction';
import FormSummaryTile from './FormSummaryTile';

type Props = {
    renderMode?: RenderMode
}

const FormList: FunctionComponent<Props> = (props) => {

    const actionKeys = (): ActionKeys => {
        return {
            publishKey: ResourceName.FORM_DEFINITION_ACTION_PUBLISH,
            newDraftKey: ResourceName.FORM_DEFINITION_ACTION_REDRAFT,
            archiveKey: ResourceName.FORM_DEFINITION_ACTION_ARCHIVE
        }
    }

    const mapRowToComponent = (row: HalResource): any => {
        return (
            <FormItemWithAction
                key={row.identity}
                tileComponent={FormSummaryTile}
                row={row}
                renderMode={props.renderMode}
                actionKeys={actionKeys()}
                editPathName="/admin/formBuilder/edit"
            />
        )
    };

    const fetchAndRender = FetchListAndRender({
        resourceName : ResourceName.ALL_FORM_DEFINITIONS,
        mapRowToComponent: mapRowToComponent
    })
   
    return (
       fetchAndRender
    );
};

export default FormList