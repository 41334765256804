import React, {FunctionComponent} from 'react';
import MultiFormCaptureHeader from './MultiFormCaptureHeader';
import MultiFormCaptureList from './MultiFormCaptureList';

const MultiFormCaptureDashboard: FunctionComponent = (prop) => {

    return (
        <div>
            <MultiFormCaptureHeader/>
            <MultiFormCaptureList/>
        </div>
    )
}

export default MultiFormCaptureDashboard