import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { HalResource } from '../../../model/common/HalResource';
import { Maybe } from '../../../model/common/Maybe';
import { Paper, Typography } from '@material-ui/core';
import MultiLanguageFormRenderer from '../../../components/form-renderer/MultiLanguageFormRenderer';
import { CrudMode } from '../../../model/common/Common';
import { ResourceName } from '../../../model/common/ResourceName';
import { useMutableHalResource } from '../../../components/hoc/withMutateHalResource';
import { useHistory } from 'react-router';
import { useHalResource } from '../../../components/hoc/withHalResource';

type Props = {
    crudMode: CrudMode,
    data: Maybe<HalResource>
}

/**
 *  functional based
*/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          width: 600,
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
    }
  }),
);

export const UserProfileForm: FunctionComponent<Props> = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const formDefFetchResult = useHalResource({resourceName: ResourceName.FORM_DEFINITION_BY_NAME, resourceParams: "userProfile"})

    const doNavigation = () => {
        console.log('post handle submit called ')
        history.push("/admin/example/userProfile/overview")
    }

    const getFormDefProps = (): any => {
        return formDefFetchResult.resource.map(res => res.properties).getOrElse({} as any)
    } 

    const getFormDef = (): any => {
        return formDefFetchResult.resource.getOrElse({} as any)
    } 

    const mutationHook = useMutableHalResource({ 
        createdResourceName : ResourceName.CREATE_FORM_CAPTURE,
        updatedResourceName : ResourceName.FORM_CAPTURE,
        postSubmit: doNavigation
    })

    const getData = () => {
        const maybe = props.data
        return maybe.map(res => res.properties).getOrElse({} as any)
    }

    const onSubmit = (event: any) => {

        const formDef = getFormDef()
        //add the form def to the payload
        console.log('using form def ', formDef)

        // We submit both form and data
        const payload = {
            form: formDef,
            data: event.data
        }

        //TODO look at simplifying this, wrap into hook ?
        if (props.crudMode === CrudMode.CREATE) {
            mutationHook.handleMutation(payload, props.crudMode)
        } else {
            //attempting to update resource
            const link = props.data.map(resource => resource.links && resource.links.get('self'))
            console.log('extracted link ', link)
            link.map(
                url => mutationHook.handleMutation(payload, props.crudMode, url)
            )
        }
    }
    
    return (
        <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" align="center">
                User Profile
            </Typography>
            <MultiLanguageFormRenderer
                formDefinition={getFormDefProps()}
                data={getData()}
                onSubmit={onSubmit}
            />
        </Paper>
    )
}