import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AccountCircle, AddBox, Home, QuestionAnswer} from '@material-ui/icons';
import HomePage from '../screens/admin/Home'
import QuestionnairePanel from '../screens/home/QuestionnairePanel';
import HorizontalStepper from '../screens/home/HorizontalStepper';
import FormSummary from '../screens/admin/form-builder/list/FormSummary';
import CreateFormDefinition from '../screens/admin/form-builder/mutation/CreateFormDefinition';
import EditFormDefinition from '../screens/admin/form-builder/mutation/EditFormDefinition';
import FormCaptures from '../screens/admin/form-capture/FormCapture';
import {RouteConfig} from './RouteConfig';
import {ResourceName} from '../model/common/ResourceName';
import EditUserProfile from '../screens/home/userProfile/EditUserProfile';
import UserProfileOverview from '../screens/home/userProfile/UserProfileOverview';
import CreateUserProfile from '../screens/home/userProfile/CreateUserProfile.';
import CreateMultiFormDefinition from '../screens/admin/multi-form/mutation/CreateMultiFormDefinition';
import EditMultiFormDefinition from '../screens/admin/multi-form/mutation/EditMultiFormDefinition';
import CreateMultiFormTemplate from '../screens/admin/multi-form/view/CreateMultiFormTemplate';
import MultiFormCaptureDashboard from "../screens/admin/form-capture/MultiFormCaptureDashboard";
import UpdateMultiFormTemplate from "../screens/admin/multi-form/view/UpdateMultiFormTemplate";

type Props = {}

export const AdminDashboardRoutes: RouteConfig[] = [
    {
        path: '/admin',
        sidebarName: 'Admin Home',
        component: HomePage,
        icon: Home
    },
    {
        path: '/admin/formBuilder', 
        sidebarName: 'Form Definitions',
        component: FormSummary,
        icon: AddBox
    },
    {
        path: '/admin/formCaptures',
        sidebarName: 'Form Captures',
        component: () => <FormCaptures queryByArtifact={'userProfile'} />,
        icon: QuestionAnswer
    }
]

export const AdminDashboardExampleRoutes: RouteConfig[] = [
    {
        path: '/admin/example/userProfile/overview', 
        sidebarName: 'User Profile',
        component: UserProfileOverview,
        icon: AccountCircle
    },
   
    {
        path: '/admin/example/wizard', 
        sidebarName: 'Horizontal Stepper',
        component: HorizontalStepper,
        icon: AccountCircle
    },
    {
        path: '/admin/example/panel', 
        sidebarName: 'Basic Panels',
        component: QuestionnairePanel,
        icon: AccountCircle
    }
]

export const AdminRoutes: RouteConfig[] = [
    {
        path: '/admin/formBuilder/refresh',
        component: FormSummary
    },
    {
        path: '/admin/formBuilder/create', 
        component: () => <CreateFormDefinition/>,
    },
    {
        path: '/admin/formBuilder/edit', 
        component: () => <EditFormDefinition resourceName={ResourceName.FORM_DEFINITION}/>,
    },
    {
        path: '/admin/formBuilder/multi/create', 
        component: CreateMultiFormDefinition ,
    },
    {
        path: '/admin/formBuilder/multi/edit', 
        component: EditMultiFormDefinition ,
    },
    {
        // Note at present preview mode also allows editing of capture data, to be refactored
        path: '/admin/formBuilder/multi/preview', 
        component: CreateMultiFormTemplate,
    },
    {
        // Note this is editing captured form data
        path: '/admin/formCapture/multi/update',
        component: UpdateMultiFormTemplate,
    },
    {
        path: '/admin/formBuilder/multi/dashboard',
        component: MultiFormCaptureDashboard,
    },
    {
        path: '/admin/example/userProfile/create', 
        component: CreateUserProfile
    },
    {
        path: '/admin/example/userProfile/edit', 
        component: EditUserProfile
    },
]

class AdminRouter extends React.Component<Props> {
    static routes = AdminDashboardRoutes.concat(AdminDashboardExampleRoutes).concat(AdminRoutes)
    render() {
        return (
           
                <Switch>
                    {AdminRouter.routes.map((route) => (
                        <Route
                            exact
                            key={route.path}
                            path={route.path}
                            component={route.component}
                        />
                    ))}
                </Switch>
        )
    }
}

export default AdminRouter