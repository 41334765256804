import React, {FunctionComponent} from 'react';

import {Grid, GridListTile, makeStyles, Paper, Typography, LinearProgress, Tooltip, IconButton, Avatar, Badge} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Moment from 'react-moment';
import { TileProps, RenderMode, TilePropsNoActions } from '../form-builder/list/FormItemWithAction';

const MultiFormDashboardSummary : FunctionComponent<TilePropsNoActions> = (props: TilePropsNoActions) => {

    const classes = useStyles();

    const getFormType = () => {
        const type = props.data.formSummary.formType
        return type ? type.toUpperCase() : "FORM"
    }

    return (
      <GridListTile cols={2} className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
            
            <Grid item xs={1}>
                <Typography data-testid="formType" variant="caption" color="textPrimary">
                    {getFormType()}
                </Typography>
            </Grid>
            <Grid item>
                    
                <Badge color="secondary" badgeContent={props.data.formSummary.totalFields}>
                    <div className={classes.shape}>
                        FIELDS
                     </div>   
                </Badge> 
            </Grid>
            <Grid item>    
                <Badge color="secondary" badgeContent={props.data.formSummary.totalSteps}>
                    <div className={classes.shape}>
                        SECTIONS
                     </div>   
                </Badge>    
            </Grid>
            <Grid item xs={2}>
                  <Typography data-testid="versionStatus" variant="body2" color="textPrimary">
                      Status: {props.data.versionStatus}
                  </Typography>
            </Grid>
            <Grid item>       
                  <Typography data-testid="version" variant="body2" color="textPrimary">
                    Version: {props.data.version}
                </Typography>
            </Grid>
            
            <Grid item>
                <Typography data-testid="createdTime" variant="body2" color="textPrimary">
                    Created: <Moment
                        format="YYYY-MM-DD HH:mm:ss"
                    >{props.data.created}
                    </Moment>
                </Typography>
            </Grid>
            <Grid item>
                <Typography data-testid="updatedTime" variant="body2" color="textPrimary">
                    Updated: <Moment
                        format="YYYY-MM-DD HH:mm:ss"
                    >{props.data.updated}
                    </Moment>
                </Typography>
            </Grid>
            </Grid>
        </Paper>
      </GridListTile>
    )
};

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: '100%',
            justifyContent: 'space-around',
            backgroundColor: theme.palette.background.paper,
            listStyle: 'none',
        },
        paper: {
            fontSize: '0.7em',
            width: '100%',
            padding: 5,
            margin: 5,
            listStyle: 'none',
        },
        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        purple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        shape: {
            backgroundColor: theme.palette.primary.main,
            width: 100,
            height: 30,
            fontSize: '1.3em',
            fontWeight: 'bold',
            color: 'white',
            alignItems: 'center',
            marginRight: '2px',
            marginLeft: '2px',
        },
    }),
);

export default MultiFormDashboardSummary