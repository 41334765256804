import * as React from 'react';
import { useObserver, } from 'mobx-react-lite';
import { useState} from 'react';
import { ResourceName } from '../../model/common/ResourceName';
import { HalResource } from '../../model/common/HalResource';
import { Maybe } from '../../model/common/Maybe';
import { useHalStore } from '../../model/store/StoreProvider';
import { Status } from './withHalContext';

type Props = {
    postDelete: any
}

export type WithHalResourceStatusProps = {
    resource: Maybe<HalResource>,
    status: Status
}
/*
* Custom Hook which acts like a state machine for creating / updating resources via the HalStore
* Uses the following status workflow INIT -> LOADING -> LOADED. 
* TOOD timeouts for request and ERROR HANDLING
* This function derives what resource to via the ResourceName property. 
*/
export const useDeleteHalResource = (props: Props) => {

    const halStore = useHalStore()
    const [status, setStatus] = useState<Status>(Status.INIT)
    const [resource, setResource] = useState<Maybe<HalResource>>(Maybe.none())
    const [resourceName, setResourceName] = useState<Maybe<ResourceName>>(Maybe.none())

    const weAreDeleted = (res: HalResource,) => {
        console.log('success we are deleted')
        const updatedStatus = res.isError === true ? Status.ERROR : Status.LOADED
        const maybeResource = Maybe.some(res)
        setStatus(updatedStatus)
        setResource(maybeResource)
        props.postDelete(maybeResource, updatedStatus)
    }    

    const checkCallBack = useObserver(() => {
        const maybe : Maybe<HalResource> = resourceName.flatMap(res => halStore.resources.get(res))
        //console.log('delete checking call back', resourceName, deleteStatus)     
        if (status === Status.LOADING) {
            maybe.map(res =>  weAreDeleted(res));
        }
        return maybe
    })

    const handleDelete = (resourceName: ResourceName, url: string= "") => {
        performDelete(resourceName, url)
    }

    const performDelete = (resourceName: ResourceName, url: string) => {
        console.log('attemping perform delete', resourceName)
        halStore.deleteResourceFromUrl(resourceName, url)
        setStatus(Status.LOADING)
        setResourceName(Maybe.some(resourceName))
        checkCallBack.map(res => res)
    }

    return {resource, deleteStatus: status, handleDelete}
}