import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, AppBar, makeStyles, Theme, createStyles, Toolbar, Grid, Tooltip, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import FormList from './form-builder/list/FormList';
import { RenderMode } from './form-builder/list/FormItemWithAction';
import MultiFormList from './multi-form/list/MultiFormList';

type Props = {}

const Home: FunctionComponent<Props> = (props : Props) => {

    const classes = useStyles()
    const { t } = useTranslation();
    const history = useHistory()
 
    const handleCreateForm = () => {
        history.push("/admin/formBuilder/create")
    };

    const handleCreateParentForm = () => {
        history.push("/admin/formBuilder/multi/create")
    };

    return (
       
        <Paper className={classes.paper}>
            <Grid 
                container
                spacing={3}
                >
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Toolbar>
                            <Typography variant="h5">
                                Form Builder {t('Dashboard')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Grid container justify="space-between">
                            <Grid item xs={6} >
                                <Typography variant="h6" className={classes.heading}>
                                    Multi Step Forms
                                </Typography>
                        
                            </Grid>
                            <Grid xs={6}item>
                                <Tooltip title="Add Multi Step Form">
                                    <Fab size="small" aria-label="Add" onClick={handleCreateParentForm} className={classes.fab} color="primary">
                                        <AddIcon />
                                    </Fab>
                                </Tooltip> 
                            </Grid>
                            <Grid xs={12} item>
                                <MultiFormList renderMode={RenderMode.SMALL}/>
                            </Grid>
                        </Grid>
                    </Paper>
                 </Grid> 
                 <Grid item xs={6}>      
                    <Paper>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Typography variant="h6" className={classes.heading}>
                                    Form Definitions
                                </Typography>
                            </Grid>
                            <Grid xs={6}item>
                                <Tooltip title="Add Form Definition">
                                    <Fab size="small" aria-label="Add" onClick={handleCreateForm} className={classes.fab} color="primary">
                                        <AddIcon />
                                    </Fab>
                                </Tooltip> 
                            </Grid>
                            <Grid xs={12} item>
                                <FormList renderMode={RenderMode.SMALL}/>
                            </Grid>
                        </Grid>
                    </Paper>   
                </Grid> 
            </Grid>
         </Paper>
            
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        appBar: {
            width: '95%',
            padding: '10px',
        },
        heading: {
            paddingTop: '2px',
            paddingLeft: '10px'
            //marginLeft: '2px',
        },
        paper: {
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            listStyle: 'none',
        },
        fab: {
            //position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: "primary"
        }
}));

export default Home