import React, { FunctionComponent, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Fab } from '@material-ui/core';
import { useLogin } from '../../components/hoc/useLogin';


const Login: FunctionComponent = () => {
    const classes = useStyles()
    const login = useLogin()

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeUserName = (event: any) => {
        setUserName(event.target.value)
    }

    const handleChangePassword = (event: any) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event: any) => {
        login.doLogin(userName, password)
    }
    
    return (
    <div className={classes.root}>
       
        <Grid container
            spacing={1}
            direction="row"
            justify="center"
            alignItems="center"
          >
        
            <Grid item xs={4}>
            
                <TextField
                    required
                    id="outlined-required"
                    label="User Name"
                    className={classes.textField}
                    onChange={handleChangeUserName}
                    InputProps={{ classes: { input: classes.input1 } }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    />
                
            </Grid>
            <Grid item xs={4}>
                
                <TextField
                    id="outlined-password-input"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    autoComplete="current-password"
                    onChange={handleChangePassword}
                    InputProps={{ classes: { input: classes.input1 } }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    />
                
            </Grid>
            <Grid item xs={4}>
                    
                    <Fab variant="extended"
                            size="small" 
                            color="primary" 
                            aria-label="add" 
                            onClick={handleSubmit}
                            className={classes.submit}>
                            Log in
                    </Fab>
                
            </Grid>
        </Grid>
    </div>
    )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        marginTop: 5,
    },
    textField: {
        color: 'white',
    },
    submit: {
        backgroundColor: 'lightgray',
        color: 'black',
    },
    input1: {
        color: 'white',
    },
    inputLabel: {
        color: 'white'
    },
    menu: {
        width: 200,
    },
  }),
);

export default Login