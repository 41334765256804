import React from 'react';
import {FormEdit} from 'react-formio';

export default class FormBuilder extends React.Component{

    render() {
       
        return (
            <div>
                <FormEdit
                    form={this.props.form}
                    onChange={this.props.onChange}
                    onSaveComponent={this.props.onSaveComponent}
                    saveForm={this.props.onSaveForm}
                    //saveForm={onSaveForm => console.log(' on save form ', onSaveForm)}
                    saveText='Save Form'
                    options={{
                        builder: {
                          basic: {
                            components: {
                                //TODO LOOK AT SIMPLER WAY OF DOING THIS
                                chipSelectorComp: true
                            }
                          },
                        }
                      }}
                />
            </div>
        )
    }
}