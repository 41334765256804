import { observable, action } from 'mobx';
import { Maybe } from './Maybe';

/* 
primarily provides safe get method so we always get a Maybe back and not undefined.
*/
export class MaybeMap<U, T> {

    @observable
    private map: Map<U, Maybe<T>>;

    private constructor(map: Map<U, Maybe<T>>){
        this.map = map
    }

    static of<U,T>(): MaybeMap<U, T> {
        const _map: Map<U, Maybe<T>> = new Map();
        return new MaybeMap(_map)
    }

    clone() : MaybeMap<U, T> {
        return new MaybeMap(this.map)
    }

    getOrNone = (t?: Maybe<T>): Maybe<T> => {
        return t ? t : Maybe.none()
    }

    has(key: U): boolean {
        return this.map.has(key)
    }

    get(key: U): Maybe<T> {
        return this.getOrNone(this.map.get(key));
    }

    @action set(key: U, value: Maybe<T>) {
        this.map.set(key, value);
    }
}

export class MaybeMapSimple<U, T> {

    private map: Map<U, Maybe<T>>;

    private constructor(map: Map<U, Maybe<T>>){
        this.map = map
    }

    static of<U,T>(): MaybeMapSimple<U, T> {
        const _map: Map<U, Maybe<T>> = new Map();
        return new MaybeMapSimple(_map)
    }

    clone() : MaybeMapSimple<U, T> {
        return new MaybeMapSimple(this.map)
    }

    getOrNone = (t?: Maybe<T>): Maybe<T> => {
        return t ? t : Maybe.none()
    }

    has(key: U): boolean {
        return this.map.has(key)
    }

    get(key: U): Maybe<T> {
        return this.getOrNone(this.map.get(key));
    }

    set(key: U, value: Maybe<T>) {
        this.map.set(key, value);
    }
}