import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography
} from "@material-ui/core";
import {DeleteForever, EditTwoTone, DraftsTwoTone, SaveTwoTone} from "@material-ui/icons";
import PublishIcon from "@material-ui/icons/Publish";
import React, {FunctionComponent} from "react";

type Props = {
    link?: any,
    onEdit?: any,
    onDelete?: any,
    onPublish?: any,
    onReDraft?: any,
    onSave?: any
}

const FromEditOptions : FunctionComponent<Props> = (props: Props) => {

    const [msg, setMsg] = React.useState("Are you sure you wish to continue")
    const [fn, setFn] = React.useState(() => { })
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const setDialog = (fn: any, msg: string) => {
        setMsg(msg)
        setFn(fn)
        setOpen(true)
        console.log("tog msg:", msg)
    }

    return (
        <>
            { open ? (
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onAbort={handleClose}>
                    Please confirm to continue...
                    </DialogTitle>
                        <DialogContent dividers>
                        <Typography gutterBottom>
                        {msg}
                        </Typography>
                        </DialogContent>
                    <DialogActions>
                        {/*TODO pass in action dynamically but how to pass function as the action?*/}
                        <Button onClick={ props.onDelete } color="primary">
                        Continue
                        </Button>
                        <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                        </Button>
                    </DialogActions>
                </Dialog>) : null
            }
            <Tooltip title="Edit" hidden={props.onEdit ? false: true}>
                <IconButton onClick={ props.onEdit } >
                    <EditTwoTone />
                </IconButton>
            </Tooltip>
            <Tooltip title="Save" hidden={props.onSave ? false: true}>
                <IconButton onClick={ props.onSave } >
                    <SaveTwoTone />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete" hidden={props.onDelete ? false: true}>
                <IconButton  onClick={ () => {setDialog(() => {}, "Are you sure you want to Delete this item? This will archive the form and potentially impact its visibility to customers.") }} >
                    <DeleteForever/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Publish" hidden={props.onPublish ? false: true}>
                <IconButton onClick={props.onPublish}>
                    <PublishIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title="New Version" hidden={props.onReDraft ? false: true}>
                <IconButton onClick={props.onReDraft}>
                    <DraftsTwoTone/>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default FromEditOptions