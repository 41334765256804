import React, {FunctionComponent} from 'react';
import {HalResource} from '../../../../model/common/HalResource';
import {ResourceName} from '../../../../model/common/ResourceName';
import FetchListAndRender from '../../../../components/common/FetchListAndRender';
import FormItemWithAction, { ActionKeys, RenderMode } from '../../form-builder/list/FormItemWithAction';
import MultiFormSummaryTile from './MultiFormSummaryTile';


type Props = {
    renderMode: RenderMode
}

const MultiFormList: FunctionComponent<Props> = (props) => {

    const actionKeys = (): ActionKeys => {
        return {
            publishKey: ResourceName.MULTI_FORM_DEFINITION_ACTION_PUBLISH,
            newDraftKey: ResourceName.MULTI_FORM_DEFINITION_ACTION_REDRAFT,
            archiveKey: ResourceName.MULTI_FORM_DEFINITION_ACTION_ARCHIVE
        }
    }
   
    const mapRowToComponent = (row: HalResource): any => {
        return (
            <FormItemWithAction
                key={row.identity}
                tileComponent={MultiFormSummaryTile}
                row={row}
                renderMode={props.renderMode}
                actionKeys={actionKeys()}
                editPathName="/admin/formBuilder/multi/edit"
            />
        )
    };

    const fetchAndRender = FetchListAndRender({
        resourceName : ResourceName.ALL_MULTI_FORM_DEFINITIONS,
        mapRowToComponent: mapRowToComponent
    })
   
    return (
       fetchAndRender
    );
};

export default MultiFormList