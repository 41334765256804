import { useStore } from "../../model/store/StoreProvider";
import { useObserver } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Maybe } from "../../model/common/Maybe";
import { useHistory } from "react-router";

export enum LoginStatus {
    INIT = "INIT",
    PROGRESS = "PROGRESS",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

/*
* Custom hook to check if user is authenticated.
* Will navigate to home page if they are not authenticated
*/
export const useAuthenticated = () => {

    const localStore = useStore()
    const history = useHistory()
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [status, setStatus] = useState<LoginStatus>(LoginStatus.INIT)

    const navigateToHome = () => {
        history.push("/")
    };

    const checkIfWeAuthenticated = (response: boolean) => {
        console.log('checkIfWeAuthenticated ', response)
        if (response === true) {
            setStatus(LoginStatus.SUCCESS)
            setAuthenticated(true)
        } else {
            setStatus(LoginStatus.FAILED)
            setAuthenticated(false)
            navigateToHome()
        }
    }

    useEffect(() => {
        console.log('useAuthenticated effect called');
        
        const checkAuthenticated = () => {
            console.log('checkAuthenticated  called');
            if (status === LoginStatus.INIT) {
                const auth = localStore.authStore.isAuthenticated
                checkIfWeAuthenticated(auth)
            }
        }

        checkAuthenticated()
        
    },[])

    return {status, authenticated}
}

/*
* Custom hook to log user in
* Will navigate to admin page on successful login
*/
export const useLogin = () => {

    const localStore = useStore()
    const history = useHistory()
    const [status, setStatus] = useState<LoginStatus>(LoginStatus.INIT)
    const [authenticated, setAuthenticated] = useState<boolean>(false)

    const navigateToAdmin = () => {
        history.push("/admin")
    };

    const checkIfWeAuthenticated = (response: boolean) => {
        console.log('checkIfWeAuthenticated ', response)
        if (response === true) {
            setStatus(LoginStatus.SUCCESS)
            setAuthenticated(true)
            navigateToAdmin()
        } else {
            setStatus(LoginStatus.FAILED)
            setAuthenticated(false)
            //todo return message ?
        }
    }

    const checkLoginOK = useObserver(() => {
                                 
        const auth = localStore.authStore.isAuthenticated
        const maybeAuth: Maybe<boolean> = auth ? Maybe.some(auth) : Maybe.none()
        console.log('check login ok ', auth, status)
        if (status === LoginStatus.PROGRESS) {
            maybeAuth.map(auth => checkIfWeAuthenticated(auth))
        }

        return maybeAuth
    })

    const doLogin = (userName: string, password: string) => {
        setStatus(LoginStatus.PROGRESS)
        localStore.authStore.login(userName, password)
        checkLoginOK.map(auth => auth)
    }

    return {status, authenticated, doLogin}
}