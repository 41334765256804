import * as React from 'react';
import { useObserver, } from 'mobx-react-lite';
import { useState} from 'react';
import { ResourceName } from '../../model/common/ResourceName';
import { HalResource } from '../../model/common/HalResource';
import { Maybe } from '../../model/common/Maybe';
import { useHalStore } from '../../model/store/StoreProvider';

import { Status } from './withHalContext';
import { CrudMode } from '../../model/common/Common';

type Props = {
    createdResourceName: ResourceName,
    updatedResourceName: ResourceName,
    postSubmit: any
}

export type WithHalResourceStatusProps = {
    resource: Maybe<HalResource>,
    status: Status
}
/*
* Custom Hook which acts like a state machine for creating / updating resources via the HalStore
* Uses the following status workflow INIT -> LOADING -> LOADED. 
* TOOD timeouts for request and ERROR HANDLING
* This function derives what resource to via the ResourceName property. 
*/
export const useMutableHalResource = (props: Props) => {

    const halStore = useHalStore()
    const [status, setStatus] = useState<Status>(Status.INIT)
    const [resource, setResource] = useState<Maybe<HalResource>>(Maybe.none())
    const [resourceName, setResourceName] = useState<Maybe<ResourceName>>(Maybe.none())

    const weAreCreated = (res: HalResource,) => {
        console.log('success we are done')

        const updatedStatus = res.isError === true ? Status.ERROR : Status.LOADED
        const maybeResource = Maybe.some(res)
        setStatus(updatedStatus)
        setResource(maybeResource)
        props.postSubmit(maybeResource, updatedStatus)
    }    

    const checkCallBack = useObserver(() => {
                                    
        const maybe : Maybe<HalResource> = resourceName.flatMap(res => halStore.resources.get(res))
        if (status === Status.LOADING) {
            maybe.map(res =>  weAreCreated(res));
        }
        return maybe
    })

    const getResourceNameToUse = (crudMode: CrudMode): ResourceName => {
        return crudMode === CrudMode.CREATE ? props.createdResourceName : props.updatedResourceName;
    }

    const handleMutation = (data: any, crudMode: CrudMode, url: string="") => {
        performMutation(data, crudMode, url)
    }

    const performMutation = (data: any, crudMode: CrudMode, url: string) => {
        console.log(`attemping perform mutation data ' ${data}, crudMode = ${crudMode}, url = ${url}`)
        const resourceName = getResourceNameToUse(crudMode);
        if (crudMode === CrudMode.CREATE) {
            halStore.createResource(resourceName, data)
        } else {
            halStore.updateResourceFromUrl(resourceName, url, data)
        }
        setStatus(Status.LOADING)
        setResourceName(Maybe.some(resourceName))
        checkCallBack.map(res => res)
    }

    return {resource, status, handleMutation}
}

useMutableHalResource.defaultProps = {
    createdResourceName: ResourceName.CREATE_FORM_DEFINITION,
    updatedResourceName: ResourceName.FORM_DEFINITION,
    postSubmit: () => {}
} as Props