import React, { FunctionComponent } from 'react';
import { UserProfileForm } from './UserProfileForm';
import { Maybe } from '../../../model/common/Maybe';
import { HalResource } from '../../../model/common/HalResource';
import { ResourceName } from '../../../model/common/ResourceName';
import { Status } from '../../../components/hoc/withHalContext';
import { CrudMode } from '../../../model/common/Common';
import { useHalResourcePassState } from '../../../components/hoc/withHalResourcePassState';

type Props = {}

/*
* Will do the job of fetching the form def and fetching the underlying data 
* Passing the form definition and data down to the child UserProfileForm component
*/
const EditUserProfile: FunctionComponent<Props> = ({}) => {
    //call hook here
    const formCaptureResult = useHalResourcePassState({resourceName: ResourceName.FORM_CAPTURE})
    
    const getStatus = (): Status => {
        return formCaptureResult.status;
    }

    const getData = (): Maybe<HalResource> => {
        return formCaptureResult.resource
    }

    return (
        <div>
            {getStatus() === Status.LOADED ? 
            (
                <UserProfileForm
                    crudMode={CrudMode.UPDATE}
                    data={getData()}
                />
            ) : 
                 (<div>Loading....</div>) 
            }
        </div>
    )
}

export default EditUserProfile