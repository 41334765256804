import * as React from 'react'
import { withStyles, createStyles } from "@material-ui/styles";
import Base from './BaseLayout';
import Header from '../header/Header'
import Welcome from '../../screens/home/welcome/Welcome';

type Props = {
    classes: any
}

class HomeLayout extends React.Component<Props> {

    render() {
        return (
            <Base header={Header}>
               <Welcome/>
            </Base>
        )
    }
}

const styles = (theme: any) => createStyles({
    root: {
        flexGrow: 1,
        padding: '50px 24px;'
    }
});

export default withStyles(styles, { withTheme: true })(HomeLayout)