import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { AppBar, Tabs, Tab, Grid, Tooltip, IconButton, Fab, Paper, Toolbar, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router";
import AddIcon from '@material-ui/icons/Add';
import { HalResource } from "../../../../model/common/HalResource";
import { useHalResource } from "../../../../components/hoc/withHalResource";
import { ResourceName } from "../../../../model/common/ResourceName";
import SectionFormDefinition, { SectionMetaData } from "./SectionFormDefinition";
import { Maybe } from "../../../../model/common/Maybe";
import { MaybeMap } from "../../../../model/common/SafeMap";
import FormEditOptions from '../../../../components/form-builder/FormEditOptions';
import { useMutableHalResource } from "../../../../components/hoc/withMutateHalResource";
import { CrudMode } from "../../../../model/common/Common";
import { TabPanel } from "./TabPanel";
import { bindMetaData, MultiFormData } from "../mapping/MultiFormMapping";
import { bindSectionMetaData } from "../mapping/SectionFormMapping";

const a11yProps = (index: any) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

type Props = {
    crudMode: CrudMode,
    resource: Maybe<HalResource>
}

const MultiFormDefinition: FunctionComponent<Props> = (props : Props) => {

    const classes = useStyles()
    const history = useHistory()

    const mapData = () => {
        return bindMetaData(props.resource)
    }

    const mapSectionMetaData = () => {
        return bindSectionMetaData(props.resource)
    }

    const [tabIndex, setTabIndex] = useState(0);
    const [formData, setFormData] = useState<MultiFormData>(mapData().getFormData())
    const [sectionList, setSectionList] = useState<Array<SectionMetaData>>(mapSectionMetaData().getSectionArray())
    const [resourceMap, setResourceMap] = useState<MaybeMap<string, HalResource>>(MaybeMap.of())
    const formDefFetchResult = useHalResource({resourceName: ResourceName.ALL_FORM_DEFINITIONS});
   
    const handleClose = () => {
       history.goBack()
    }

    const mutationHook = useMutableHalResource({ 
        createdResourceName: ResourceName.CREATE_MULTI_FORM_DEFINITION,
        updatedResourceName: ResourceName.MULTI_FORM_DEFINITION,
        postSubmit: handleClose
    })
    
    const getLookupData = (): Array<HalResource>  => {
        const maybe = formDefFetchResult.resource;
        return maybe ? maybe.map(res => res.resource['content'] as Array<HalResource>).getOrElse([]) : []
    };

    const getFormDefsByStatus = (status: string):Array<HalResource> => {

        const groupedMap: Map<string, Array<HalResource>> = getLookupData().reduce(
            (entryMap, row) => entryMap.set(row['versionStatus'], [...entryMap.get(row['versionStatus'])||[], row]),
            new Map()
        );

        const getStatus = groupedMap.get(status)
        return getStatus ? getStatus : []
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const getResourceByIdentity = (identity: any): Maybe<HalResource> => {
        
        if (identity) {
            const found = getLookupData().filter(res => res.identity === identity)
            return found.length>0 ? Maybe.some(found[0]) : Maybe.none()
        }
        return Maybe.none()
    }

    /*
    * This gets called by any field changes in the child SectionFormDefinition Component
    */
    const handleSectionChange = (evt: React.ChangeEvent<{}>) => {
        const target = evt.target;
        const value = target['value'];
        const names = target['name'].split(".");
        console.log('handle changed evt ', names, value)
        let existing = sectionList
        let existingResources = resourceMap
        const arrayIndex = names[0].charAt(names[0].length -1)
        console.log('existing ', existing)
        if (names[1] === 'identity') {
            const maybe = getResourceByIdentity(value)
            console.log('we have resource ', maybe)
            existingResources.set('identity' + arrayIndex, maybe)  
            setResourceMap(existingResources.clone())

            //basically copy the info from the specifice form def into the section
            const formSummary = maybe.map(res => res.properties['formSummary']).getOrElse({})
            if (existing[arrayIndex]) {
                existing[arrayIndex].identity = value
                existing[arrayIndex].formSummary = formSummary
                setSectionList(existing)
            }
        } else {
            existing[arrayIndex].title = value
            //note clone the array to trigger re-render
            setSectionList([...existing])
        }   
    }

    const handleChangeFormData = (evt: React.ChangeEvent<{}>) => {
        const target = evt.target; 
        setFormData({
            ...formData,
            [evt.target['name']]: evt.target['value']
        });
    }

    const handleSave = (event: React.ChangeEvent<{}>) => {
        console.log('handle save called ', sectionList)
        
        const payload = {
            name: formData.formName,
            formData,
            sections: sectionList
        }

        console.log('payload', payload)

        //TODO look at simplifying this, wrap into hook ?
        if (props.crudMode === CrudMode.CREATE) {
            mutationHook.handleMutation(payload, props.crudMode)
        } else {
            //attempting to update resource
            const link = props.resource.map(resource => resource.links && resource.links.get('self'))
            console.log('extracted link ', link)
            link.map(
                url => mutationHook.handleMutation(payload, props.crudMode, url)
            )
        }
    }

    const addTab = (event: any) => {
        console.log('attempting to add tab ')
        const currentIndex = sectionList.length
        setTabIndex(currentIndex)

        let existing = sectionList
        existing.push(mapSectionMetaData().newSection(currentIndex))
        setSectionList(existing)
    }

    const removeTab = (event: any, tabIndex: number) => {
        console.log('attempting to remove tab ', tabIndex)
        //only remove if we have more than 1 section
        //TODO PROMPT HERE
        if (sectionList.length > 1) {
            let existing = sectionList

            const newArray =  [
                ...existing.slice(0, tabIndex),
                ...existing.slice(tabIndex + 1)
            ] 
        
            setSectionList(newArray)
            //reset tab index to previous unless we are on the first tab
            if (tabIndex>0) {
                setTabIndex(tabIndex-1)
            } else {
                setTabIndex(tabIndex)
            }
        }
    }

    return (
        <Paper className={classes.paper}>
        <Grid>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.header}
            >
                <Grid>
                    <h3>Form Builder</h3>
                </Grid>
                <Grid>
                    <FormEditOptions
                        onSave={handleSave}
                    />
                    <Tooltip title="Close form edit without saving...">
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

        <Grid item>
            <TextField
                className={classes.formControl}
                label="Name"
                id="formName"
                name='formName'
                variant="outlined"
                required
                value={formData.formName}
                onChange={handleChangeFormData}
            />
                <FormControl 
                    variant="outlined" 
                    className={classes.formControl}>
                    <InputLabel 
                        id="formTypeLabel">
                        Form Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        inputProps={{
                            name: "formType",
                            id: 'formType-simple',
                        }}
                        value={formData.formType}
                        onChange={handleChangeFormData}
                        >
                    
                        <MenuItem value={'wizard'}>Wizard</MenuItem>
                        <MenuItem value={'module'}>Module</MenuItem>
                
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={classes.formControl}
                    label="Description"
                    id="formDescription"
                    name='formDescription'
                    variant="outlined"
                    required
                    value={formData.formDescription}
                    onChange={handleChangeFormData}
                />
            </Grid>   
            <Grid item>
            <AppBar position="static" color="default" className={classes.appBar}>
                <Toolbar>

                <Tooltip title="Add Section">
                    <Fab aria-label="Add" onClick={addTab} className={classes.fab} color="primary">
                        <AddIcon />
                    </Fab>
                </Tooltip> 
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                   
                    {sectionList.map((tab, index) => (
                        <Tab key={index} label={tab.title} {...a11yProps(index)} />
                    ))}
                     
                </Tabs>
                </Toolbar>
            </AppBar>
            
                {sectionList.map((section, index) => (
                    <TabPanel key={index} value={tabIndex} index={index}>
                        
                        <SectionFormDefinition
                            key={index}
                            handleLookupData={getFormDefsByStatus}
                            sectionData={section}
                            handleChange={handleSectionChange}
                            sectionNumber={index}
                            resource={resourceMap.get('identity' + index)}
                            removeTab={removeTab}
                        />
                        
                    </TabPanel>
                ))}
        
        </Grid>
    </Grid>
    </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        header: {
            padding: '10px'
        },
        appBar: {
            width: '95%',
            marginLeft: theme.spacing(3),
        },
        paper: {
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
              marginBottom: theme.spacing(6),
              padding: theme.spacing(3),
            }
        },
        formControl: {
            margin: theme.spacing(3),
            minWidth: 300,
        },
        fab: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: "primary"
        }
}));

export default MultiFormDefinition