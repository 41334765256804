import React from 'react';
import {Form} from 'react-formio';
import { withTranslation } from 'react-i18next';
import formioEn from '../../locales/en/common.json'
import formioDe from '../../locales/de/common.json'

/*
* Note this expects the form capture data in the following structure
* data: {
    "username" : "bob"
    ..etc
}
*/
class FormRenderer extends React.Component{

    componentDidMount() {
        if (this.props.doSubmit) {
            this.props.doSubmit(this.submit.bind(this));
        }
        if (this.props.onNextPage) {
            this.props.onNextPage(this.nextPage.bind(this));
        }
        if (this.props.onPreviousPage) {
            this.props.onPreviousPage(this.prevPage.bind(this));
        }
    }

    componentDidUpdate() {
        console.log('comp did update, current data ', this.props.data)
    }

    onChange = (onChangeEvent) => {
        //console.log("on change callled ", onChangeEvent)
        
        //console.log('change is', onChangeEvent.changed )
        if (onChangeEvent.changed) {
          
            console.log('change field is', onChangeEvent.changed.component.key )
            if (onChangeEvent.changed.component.key === 'industryType') {
                //magically submit the form if we have changed this field 
                //this.submit()
            }
        }
    }

    submit = (event) => {
        console.log("submit called ", event)
        if (this.myForm && this.myForm.formio) {
            console.log('my form ', this.myForm.formio)
            this.myForm.formio.submit()
        }
    }

    nextPage = (event) => {
        console.log("nextPage called ", event)
        if (this.myForm && this.myForm.formio) {
            this.myForm.formio.nextPage()
        }
    }

    prevPage = (event) => {
        console.log("prevPage called ", event)
        if (this.myForm && this.myForm.formio) {
            this.myForm.formio.prevPage()
        }
    }

    bindData = (data) => {
        if (this.myForm && this.myForm.formio) {
            this.myForm.formio.submission = data
        }
    }

    getForm = (language, data) => {
        return (<Form 
                 ref={form => this.myForm = form}
                 form={this.props.formDefinition}
                 options={{...{
                     template: 'bootstrap', 
                     iconset: 'fa', 
                     language: language,
                     //i18n: i18n
                     
                     i18n: {
                        'en': formioEn,
                        'de': formioDe
                    },
                    buttonSettings: {
                        showCancel: false,
                        showPrevious: false,
                        showNext: false,
                        showSubmit: false
                    },
                    //
                    breadcrumbSettings: {
                        clickable:false,
                    },
                }
                }}
                 onSubmit={this.props.onSubmit}
                 submission={data}
                 //onError={this.props.error}
                 onError={error => console.log('on error ', error)}
                 onChange={this.onChange}
                 onCustomEvent={this.props.onCustomEvent}
                 onNextPage={event => console.log('on next page ', event)}
                 onPreviousPage={event => console.log('on prev page ', event)}
                />)
    }

    render() {
        //const { t } = this.props
        const {language} = this.props
        console.log('we have data ', this.props.data);
        const form = this.getForm(language, this.props.data)
       
        return (
            <div>
                {form}
            </div>
        )
    }
}

export default withTranslation() (FormRenderer)