import React, {FunctionComponent, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {EditTwoTone} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Status, withHalContext} from '../../../components/hoc/withHalContext';
import {HalResource} from '../../../model/common/HalResource';
import {ResourceName} from '../../../model/common/ResourceName';
import {Button, Grid, Tooltip, IconButton} from "@material-ui/core";
import {useHalResourcePassState} from "../../../components/hoc/withHalResourcePassState";
import JsonViewer from "react-json-view";
import { useHistory } from 'react-router';
import {ResourceBuilder} from "../../../model/common/ResourceBuilder";
import Moment from 'react-moment';

const MultiFormCaptureList: FunctionComponent = (prop) => {

    const history = useHistory()

    const multiFormCaptureData = useHalResourcePassState({
        resourceName: ResourceName.MULTI_FORM_DEFINITION_CAPTURE_DATA, 
        resourceParam: 'formCaptureUrl'
    });

    const navigateToPreview = (row: HalResource) => {
        const url: string = multiFormCaptureData.resource.map(res => res.links && res.links.get('MULTI_FORM_DEFINITION_SECTIONS') as string).getOrElse("") as string
        const linksAsMap = ResourceBuilder.transform(row.links)
        const selfLink = linksAsMap.get('self');
        history.push({
            pathname: "/admin/formCapture/multi/update",
            state:{
                resourceUrl: url,
                formCaptureUrl: selfLink
            }
        })
    }

    const getData = (): Array<HalResource> => {
        const maybe = multiFormCaptureData.resource;
        console.log("Multi Form Captures ", maybe, multiFormCaptureData.status);
        const list = maybe ? maybe.map(res => res.resource['content'] as Array<HalResource>).getOrElse([]) : [];
        console.log('Got Multi Form Capture results list ', list);
        return list;
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                {multiFormCaptureData.status !== Status.LOADED && (<LinearProgress />)}
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id/UUID</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Properties</TableCell>
                            <TableCell align="right">Updated Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getData().map( ( row : HalResource , index  ) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">{row.identity} <br/> {row['uuid']}</TableCell>
                                <TableCell align="left">
                                    <Tooltip title="Edit">
                                        <IconButton onClick={ () => {navigateToPreview(row)}} >
                                            <EditTwoTone />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="left"><JsonViewer enableClipboard={false} collapsed={true} src={row['source']}/></TableCell>
                                <TableCell align="left"><JsonViewer enableClipboard={false} collapsed={true} src={row.properties}/></TableCell>
                                <TableCell align="right">
                                    <Moment format="YYYY-MM-DD HH:mm:ss">
                                        {row['updatedTime']}
                                    </Moment>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
};

export default MultiFormCaptureList