import React, { FunctionComponent } from 'react';
import { ResourceName } from '../../../model/common/ResourceName';
import { HalResource } from '../../../model/common/HalResource';
import UserProfileSummaryTile from './UserProfileSummaryTile';
import FetchListAndRender from '../../../components/common/FetchListAndRender';

const UserProfileList: FunctionComponent = () => {
    
    const mapRowToComponent = (row: HalResource) => {
        return (
            <UserProfileSummaryTile
                link={row.links}
                resource={row}
            />
        )
    }

    const fetchAndRender = FetchListAndRender({
        resourceName: ResourceName.FORM_CAPTURE_BY_ARTIFACT, 
        resourceParams: 'userProfile',
        mapRowToComponent: mapRowToComponent
    })
   
    return (
       fetchAndRender
    );
}
export default UserProfileList