import React, { Suspense } from 'react';
import './App.css';

import logo from './logo.svg';
import HomeRouter from './router/HomeRouter';
import { BrowserRouter } from 'react-router-dom';

const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

const App = () => {

  return (
    <Suspense fallback={<Loader/>}>
      <BrowserRouter>
        <HomeRouter />
      </BrowserRouter>
       
    </Suspense>
  );
}

export default App 

