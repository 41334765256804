import * as React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { ResourceName } from '../../model/common/ResourceName';
import { HalResource } from '../../model/common/HalResource';
import { Maybe } from '../../model/common/Maybe';
import { useHalStore } from '../../model/store/StoreProvider';

import { Status } from './withHalContext';

type Props = {
    resourceName: ResourceName,
    resourceParams?: any,
    forceRefresh?: boolean
}

export type WithHalResourceStatusProps = {
    resource: Maybe<HalResource>,
    status: Status
}

/*
* HOC Component to wrap useHalResource so it can be used in class based in components
*/
export const withHalResourceHOC = (WrappedComponent: React.ComponentType<any>) => {

    return observer((props: Props) => {
        const result = useHalResource(props)
        return <WrappedComponent resource={result.resource} status={result.status} {...props} />;
    });
};

/*
* Custom Hook which acts like a state machine for accessing resources via the HalStore
* Uses the following status workflow INIT -> LOADING -> LOADED OR ERROR. 
* TOOD timeouts for request
* This function derives what resource to via the ResourceName property. 
*/
export const useHalResource = (props: Props, resourceUrl: Maybe<string> = Maybe.none()) => {

    const halStore = useHalStore()
    const [status, setStatus] = useState<Status>(Status.INIT)
    const [resource, setResource] = useState<Maybe<HalResource>>(Maybe.none())

    const weAreLoaded = (res: HalResource, stat: Status) => {
        if (res.isError === true) {
            setStatus(Status.ERROR)
        } else {
            setStatus(Status.LOADED)
        }
        setResource(Maybe.some(res))
    }

    const checkAndGetResource = useObserver(() => {
        const maybe : Maybe<HalResource> = halStore.resources.get(props.resourceName);
        if (status === Status.LOADING) {
            maybe.map(res =>  weAreLoaded(res, status));
        }
        return maybe
    })

    const performGet = () => {
        if (resourceUrl.hasValue() === true) {
            resourceUrl.map(url =>  halStore.getResourceFromUrl(props.resourceName, url))
        } else {
            halStore.getResource(props.resourceName, props.resourceParams, true)
        }
    }

    const refresh = () => {
        setResource(Maybe.none())
        performGet()
        setStatus(Status.LOADING)
        checkAndGetResource.map(res => res)
    }

    useEffect(() => {
         
        const fetchResource = () => {
            if (status === Status.INIT) {
                performGet()
                setStatus(Status.LOADING)
                checkAndGetResource.map(res => res)
            }
        }

        fetchResource()
        
    },[])

    return {resource, status, refresh}
}