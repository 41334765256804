import React, {FunctionComponent} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from "@material-ui/core/LinearProgress";
import JsonViewer from 'react-json-view';
import {Status, withHalContext} from '../../../components/hoc/withHalContext';
import {HalResource} from '../../../model/common/HalResource';
import {useHalResource} from '../../../components/hoc/withHalResource';
import {ResourceName} from '../../../model/common/ResourceName';
import {AppBar, Button, Grid, Toolbar, Typography} from "@material-ui/core";
import {ResourceBuilder} from "../../../model/common/ResourceBuilder";
import {useHistory} from "react-router";
import {useHalResourcePassState} from "../../../components/hoc/withHalResourcePassState";

const FormCapture: FunctionComponent = () => {

    const history = useHistory()

    const formCaptureFetchResult = useHalResource({
        resourceName: ResourceName.ALL_FORM_CAPTURES,
    });

    const getAllForms = (): Array<HalResource> => {
        const maybe = formCaptureFetchResult.resource;
        console.log("Form Captures ", maybe, formCaptureFetchResult.status);
        const list = maybe ? maybe.map(res => res.resource['content'] as Array<HalResource>).getOrElse([]) : [];
        console.log('Got Form Capture results list ', list);
        return list;
    };

    const navigateToPreview = (row: HalResource) => {
        const linksAsMap = ResourceBuilder.transform(row.links)
        const selfLink = linksAsMap.get('self');
        const sectionsLink = linksAsMap.get('MULTI_FORM_DEFINITION_SECTIONS')
        console.log('LOGGING  SECTIONS URL * CHECK ID IS FOR FORM NOT CAPTURE', sectionsLink)
        console.log('LOGGING SELF', selfLink)
        //TODO this currently
        // history.push({
        //     pathname: "/admin/formCapture/multi/update",
        //     state:{
        //         resourceUrl: sectionsLink,
        //         formCaptureUrl: selfLink
        //     }
        // })
    }

    return (
        <Grid item xs={12}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h5">
                        All Form Captures
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer component={Paper}>
                {formCaptureFetchResult.status === Status.LOADING && (<LinearProgress />)}
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id/UUID</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Properties</TableCell>
                            <TableCell align="right">CreatedTime</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getAllForms().map( ( row : HalResource , index  ) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.identity} <br/> {row['uuid']}
                                </TableCell>
                                <TableCell align="left"><Button variant="contained" onClick={() => {navigateToPreview(row)} }>View in form</Button></TableCell>
                                <TableCell align="left"><JsonViewer enableClipboard={false} collapsed={true} src={row['source']}/></TableCell>
                                <TableCell align="left"><JsonViewer enableClipboard={false} collapsed={true} src={row.properties}/></TableCell>
                                <TableCell align="right">{row['createdTime']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
};

export default withHalContext(FormCapture)