import * as React from 'react';
import { useHalStore } from '../../model/store/StoreProvider';
import { useLocation} from "react-router";
import { useState, useEffect } from 'react';
import { ResourceName } from '../../model/common/ResourceName';
import { HalResource } from '../../model/common/HalResource';
import { Maybe } from '../../model/common/Maybe';
import { observer, useObserver } from 'mobx-react-lite';
import { Status } from './withHalContext';

type Props = {
    resourceName: ResourceName,
    resourceParam?: string
}

export type WithHalResourceStatusProps = {
    resource: Maybe<HalResource>,
    status: Status
}

export const withHalPassStateHOC = (WrappedComponent: React.ComponentType<any>) => {

    return observer((props: Props) => {
        const result = useHalResourcePassState(props)
        return <WrappedComponent resource={result.resource} status={result.status} {...props} />;
    });
};

/*
* Hook which acts like a state machine for accessing resources via the HalStore
* Uses the following status workflow INIT -> LOADING -> LOADED. 
* TOOD timeouts for request and ERROR HANDLING
* This function derives what resource to load via the location.state.resourceUrl from react-router.
*/
export const useHalResourcePassState = (props: Props) => {

    const halStore = useHalStore()
    const location = useLocation();
    const [status, setStatus] = useState<Status>(Status.INIT);
    const [resource, setResource] = useState<Maybe<HalResource>>(Maybe.none());
    const [resourceParam, setResourceParam] = useState<string>(props.resourceParam? props.resourceParam: 'resourceUrl');
    const [resourceUrl, setResourceUrl] = useState<string>("")

    const weAreLoaded = (res: HalResource, stat: Status) => {
        //console.log('we are loaded ', res, stat)
        setStatus(Status.LOADED)
        setResource(Maybe.some(res))
    }

    const checkAndGetResource = useObserver(() => {
        //console.log('check and get ', status);
        const maybe : Maybe<HalResource> = halStore.resources.get(props.resourceName);
        if (status === Status.LOADING) {
            maybe.map(res =>  weAreLoaded(res, status));
        }
        return maybe
    })

    useEffect(() => {
        //console.log('use effect called', resource, status);
        const fetchResource = () => {
            console.log('fetchResource called', resource, location);
            //const location = getLocation()
           
            if (status === Status.INIT && location && location.state) {
                const fetchedResourceUrl = location.state[resourceParam]
                console.log('we have resource url ', fetchedResourceUrl)
                if (fetchedResourceUrl) {
                    setStatus(Status.LOADING)
                    setResourceUrl(fetchedResourceUrl)
                    halStore.getResourceFromUrl(props.resourceName, fetchedResourceUrl)
                    checkAndGetResource.map(res => res)
                }
            }
        }
        fetchResource()
    },[])

    //TODO
    // consider how we consistency represent our HAL state/objects, this resource URL is a bit of a hack to get the ID
    // of the resource we are calling... If we are embracing HAL with links to represent permitted calls then perhaps not
    return {resource, status, resourceUrl}
}