import React, { Component, useState, FunctionComponent } from "react";
import ChipSelector from "../../form-components/ChipSelector";

type Props = {
    value: any,
    component: any,
    onChange: any
}

type State = {
  value: any
}
/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 * 
 * NOTE DOES NOT WORK WITH FunctionalComponent
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ChipSelectorComp = class extends Component<Props, State> {

    constructor(props: Props) {
      super(props);
      this.state = {
        value: props.value
      };
    }
  
    setValue = (event: any) => {
      console.log('set value called ', event)  
      
      this.setState({
        value: event
      })

      this.props.onChange(null, event)
      /*
      this.setState(
        prevState => ({ value: !prevState.value }),
        () => this.props.onChange(null, event)
      );
      */
    };
  
    render() {
      return (
          <ChipSelector 
            onChange={this.setValue}
          />
      );
    }
  };

  export default ChipSelectorComp