import React, {FunctionComponent} from 'react';
import { Grid, makeStyles, Paper, Typography, Avatar, Badge} from '@material-ui/core';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import FormEditOptions from '../../../../components/form-builder/FormEditOptions'
import {createStyles, Theme} from '@material-ui/core/styles';

import Moment from 'react-moment';
import { FormSummaryData } from '../../../../model/common/ResourceDataTypes';

type Props = {
    data: FormSummaryData
}

const FormSummarySimpleTile : FunctionComponent<Props> = (props: Props) => {

    const classes = useStyles();

    const getFormType = () => {
        const type = props.data.formSummary.formType
        return type ? type.substring(0,1).toUpperCase() : "F"
    }

    return (
      
        <Paper className={classes.paper}>
          <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
            <Grid item >
                <Typography data-testid="formName" variant="body2" color="textPrimary">
                  {props.data.formName}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Avatar className={classes.orange}>{getFormType()}</Avatar>
            </Grid>
            <Grid item xs={1}>
                <Badge color="secondary" badgeContent={props.data.formSummary.totalFields}>
                    <div className={classes.shape}>
                        FIELDS
                     </div>   
                </Badge>    
            </Grid>
            <Grid item>
                  <Typography data-testid="versionStatus" variant="body2" color="textPrimary">
                      Status: {props.data.versionStatus}
                  </Typography>
                  <Typography data-testid="version" variant="body2" color="textPrimary">
                    Version: {props.data.version}
                </Typography>
              </Grid>
            <Grid item >
                <Grid>
                    <Grid item>
                        <Typography data-testid="createdTime" variant="caption" color="textPrimary">
                            Created: <Moment>{props.data.created}</Moment>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography data-testid="updatedTime" variant="caption" color="textPrimary">
                            Updated: <Moment>{props.data.updated}</Moment>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormEditOptions 
                    link={props.data.link}
                    onEdit={() => {}} 
                />
            </Grid>
          </Grid>
        </Paper>
     
    )
};

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: '100%',
            justifyContent: 'space-around',
            backgroundColor: theme.palette.background.paper
        },
        paper: {
            fontSize: '0.7em',
            width: '100%',
            padding: 5,
            margin: 5,
        },
        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        purple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        shape: {
            backgroundColor: theme.palette.primary.main,
            width: 40,
            height: 20,
            color: 'white',
            alignItems: 'center'
        },
    }),
);

export default FormSummarySimpleTile