import {Maybe} from "../../../../model/common/Maybe";
import {HalResource} from "../../../../model/common/HalResource";
import { SectionMetaData } from "../mutation/SectionFormDefinition";
import { FormSummary } from "./MultiFormMapping";

export const bindSectionMetaData = (resource: Maybe<HalResource>) => {

    const newSection = (index: number) : SectionMetaData => {
        return {
            identity: '',
            uuid: '', 
            title: 'Section ' + (index +1), 
            formSummary: {
                formType: '',
                totalFields: 0,
                totalSteps: 0
            }
        }
    }

    const getSectionArray = (): Array<SectionMetaData> => {
        
        const maybeArray: Array<SectionMetaData> = resource.map(res => res.properties['sections']).getOrElse([newSection(0)])
        return maybeArray
    }

    const sectionFormDefinition = (index: number) : any => {
        return resource.map(res => res.embedded['sections'][index] && res.embedded['sections'][index]).getOrElse({})
    }

    const sectionFormDefinitionProps = (index: number) : any => {
        return resource.map(res => res.embedded['sections'][index] && res.embedded['sections'][index]['properties']).getOrElse({})
    }

    const getSectionFormSummary = (index: number): FormSummary => {

        const formDef = sectionFormDefinitionProps(index)
        return formDef['formSummary']
    }

    return {getSectionArray, sectionFormDefinition, sectionFormDefinitionProps, getSectionFormSummary, newSection}
}