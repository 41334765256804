import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomeLayout from '../components/layouts/HomeLayout';
import AdminLayout from '../components/layouts/AdminLayout';
import { RouteConfig } from './RouteConfig';

type Props = {}

export const HomeRoutes: RouteConfig[] = [
    {
        path: '/', 
        component: HomeLayout,
    },
    {
        path: '/admin', 
        component: AdminLayout,
    },
]

class HomeRouter extends React.Component<Props> {
    static routes = HomeRoutes
    render() {
        return (
                <Switch>
                    {HomeRoutes.map((route) => (
                        <Route
                            exact={route.path === '/'}
                            key={route.path}
                            path={route.path}
                            component={route.component}
                        />
                    ))}
                </Switch>  
        )
    }
}

export default HomeRouter