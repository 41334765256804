import React, { useState } from "react";
import { FunctionComponent } from "react";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import FormRenderer from "../../../../components/form-renderer/FormRenderer";
import { HalResource } from "../../../../model/common/HalResource";
import { useStore } from "../../../../model/store/StoreProvider";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    data: any,
    sectionIndex: number,
    doSubmit: any,
    formDefinition: HalResource,
    onSubmit: any,
    onNavigateStep: any
}

type FieldValue = {
    label: string,
    value: string
}

type FieldData = {
    values : Array<FieldValue>
}

type FieldComponent = {
    key: string,
    label: string,
    type: string,
    tags: Array<string>,
    data: FieldData,
    hidden: boolean,
    hideLabel: boolean
}

const bindWizardMetaData = (formDefinition: HalResource) => {

    const getComponents = () : Array<FieldComponent> => {

        const components: Array<FieldComponent> = formDefinition['components']

        //components.forEach(x1 => x1.hideLabel = true);
        
        return components ? components : []
    }

    const getFormDefinition = (): any => {

        const mutatedFormDef = {
            components: getComponents(),
            display: "wizard",
            name: "mut wizard",
            //path: "mut wizard",
        }

        //return mutatedFormDef
        return formDefinition
    }

    const getComponentSize = (): number => {
        return getComponents().length
    }

    return {getComponents, getComponentSize, getFormDefinition}
}

const WizardSectionStep: FunctionComponent<Props> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const rootStore = useStore()
    const [nextPageFn, setNextPageFn] = useState<any>();
    const [previousPageFn, setPreviousPageFn] = useState<any>();

    const formWizardMetaData = () => {
        return bindWizardMetaData(props.formDefinition)
    }

    const [wizardSteps, setWizardSteps] = useState<number>(formWizardMetaData().getComponentSize());
    const [activeStep, setActiveStep] = useState<number>(0);

    const getCurrentLanguage = () => {
        return rootStore.localStore.currentLanguage
    }

    const onSectionSubmit = (data: any) => {
        console.log('attempting to submit ', data, props.sectionIndex)
        props.onSubmit(data, props.sectionIndex)
    }

    const onNavigateStep = (currentStep: number, backForward: number) => {
        console.log('on navigate step ', currentStep)
        props.onNavigateStep(currentStep, wizardSteps, backForward)
    }

    const handleNextPage = (event: any) => {
        event.preventDefault()
        console.log('handle next page, ', event, )
        nextPageFn(event)
        const newStep = activeStep + 1
        setActiveStep(newStep);
        onNavigateStep(newStep, 1)
    }

    const handlePreviousPage = (event: any) => {
        event.preventDefault()
        previousPageFn(event)
        const newStep = activeStep - 1
        setActiveStep(newStep);
        onNavigateStep(newStep, -1)
    }

    const getFormData = () => {
        console.log('form data is ', props.data)
        return props.data
    }

    const bindOnNextPageFn = (onNextFn: any) => {
        setNextPageFn(() => onNextFn)
    }

    const bindOnPrevPageFn = (onPrevFn: any) => {
        setPreviousPageFn(() => onPrevFn)
    }

    return (

        <div>
              {/* <WizardProgress totalNumberSteps={15}/> */}
              <FormRenderer 
                    language={getCurrentLanguage()}
                    doSubmit={props.doSubmit}
                    formDefinition={formWizardMetaData().getFormDefinition()}
                    data={getFormData()}
                    onSubmit={onSectionSubmit}
                    onNextPage={bindOnNextPageFn}
                    onPreviousPage={bindOnPrevPageFn}
                /> 
                 <div className={classes.buttons}>
                        {activeStep !== 0 && (
                            <Button 
                                onClick={handlePreviousPage} 
                                className={classes.button}>
                                {t('previous')}
                            </Button>
                        )}
                        {activeStep < wizardSteps - 1 && ( 
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleNextPage}
                                className={classes.button}
                                >
                                {t('next')}
                            </Button>
                        )}
                 </div>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
    }),
);

export default WizardSectionStep