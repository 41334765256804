import * as React from 'react'
import { FunctionComponent } from 'react';
import { createStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Typography, Theme, makeStyles } from '@material-ui/core';

import logo from '../../assets/images/sedex-logo.svg';
import Login from '../../screens/login/Login';

const Header: FunctionComponent = () => {

    const classes = useStyles();
    return (
        <header>
            <AppBar position="fixed">
                <Toolbar className={classes.appBar}>
                    <div className={classes.logos}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start"
                            //onClick={handleDrawerToggle}
                            >
                            <img alt="" src={logo} height={20} />
                        </IconButton>
                        <Typography variant="h6" noWrap>Sedex Global</Typography>
                    </div>
                    <Login/>
                </Toolbar>
            </AppBar>
        </header>
    )
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        icon: {
            marginRight: 8
        },
        language: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        logos: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: "center"
        }, 
        appBar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'black',
            color: '#fff',
            alignItems: "center"
        },
        adminButtonRight: {
            marginLeft: 'auto',
            color: 'white'
        },
    }),
);

export default Header